define("buildpack-registry/services/moment", ["exports", "ember", "buildpack-registry/config/environment", "ember-moment/services/moment"], function (_exports, _ember, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _moment.default.extend({
    defaultFormat: _ember.default.get(_environment.default, 'moment.outputFormat')
  });
  _exports.default = _default;
});