define("ember-data-factory-guy/converter/drf-fixture-converter", ["exports", "ember-data-factory-guy/converter/json-fixture-converter"], function (_exports, _jsonFixtureConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   Convert base fixture to the Ember Django Serializer expected payload.
  
   */
  class DrfFixtureConverter extends _jsonFixtureConverter.default {
    /**
     * All lists will use "results" as payload key
     *
     * @param _
     * @param fixture
     * @returns {*}
     */
    createPayload(_, fixture) {
      if (this.listType) {
        return {
          results: fixture,
          next: null,
          previous: null,
          count: fixture.length
        };
      }
      return fixture;
    }
  }
  _exports.default = DrfFixtureConverter;
});