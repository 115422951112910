define("buildpack-registry/components/support-method-card", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    classNames: ['flex', 'flex-auto', 'br1', 'supportMethods', 'mb2', 'items-center'],
    classNameBindings: ['isSelected:shadow-outer-2', 'isSelected:bg-white', 'isNotSelected:shadow-outer-1', 'isNotSelected:bg-lightest-silver'],
    selectedSupportMethod: (0, _computed.alias)('changeset.supportMethod'),
    isNotSelected: (0, _computed.not)('isSelected'),
    isSelected: (0, _object.computed)('selectedSupportMethod', 'supportMethod', function () {
      return this.get('supportMethod') === this.get('selectedSupportMethod');
    })
  });
  _exports.default = _default;
});