define("buildpack-registry/templates/components/support-methods/email", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "G7pJVkCs",
    "block": "[[[10,0],[14,0,\"f4 mb1 dark-gray\"],[12],[1,\"Email address\"],[13],[1,\"\\n\"],[10,0],[14,0,\"gray lh-copy\"],[12],[1,\"Issues will be reported through email\"],[13],[1,\"\\n\"],[41,[33,1],[[[6,[39,2],null,[[\"changeset\",\"property\"],[[33,3],\"supportEmail\"]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,4],null,[[\"id\",\"value\",\"class\"],[\"buildpack-support-address\",[30,1,[\"value\"]],\"hk-input w-100 mt2\"]]]],[1,\"\\n    \"],[1,[30,1,[\"errorsList\"]]],[1,\"\\n\"]],[1]]]]]],[]],null]],[\"validationResult\"],false,[\"if\",\"isSelected\",\"field-validations\",\"changeset\",\"input\"]]",
    "moduleName": "buildpack-registry/templates/components/support-methods/email.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});