define("buildpack-registry/routes/buildpacks/buildpack-edit", ["exports", "@ember/routing/route", "rsvp"], function (_exports, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    model(params) {
      const buildpack = this.get('store').find('buildpack', params.buildpack_id);
      return _rsvp.default.hash({
        buildpack
      });
    }
  });
  _exports.default = _default;
});