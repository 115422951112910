define("buildpack-registry/templates/components/buildpack-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "1PuZRSfZ",
    "block": "[[[1,[28,[35,0],null,[[\"buildpacks\"],[[33,1]]]]],[1,\"\\n\\n\"],[1,[28,[35,0],null,[[\"buildpacks\"],[[33,2]]]]],[1,\"\\n\\n\"],[1,[28,[35,0],null,[[\"buildpacks\"],[[33,3]]]]],[1,\"\\n\"]],[],false,[\"buildpack-list-type\",\"userBuildpacks\",\"enterpriseAccountBuildpacks\",\"teamBuildpacks\"]]",
    "moduleName": "buildpack-registry/templates/components/buildpack-list.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});