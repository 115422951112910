define("buildpack-registry/templates/buildpacks", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "InyHBhRh",
    "block": "[[[10,0],[14,0,\"bg-lightest-silver w-100 flex h4 ph2 ph3-l items-center bb bt b--light-silver\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"w-100 center mw9 flex items-center nudge-down--1\"],[12],[1,\"\\n\"],[6,[39,0],[\"index\"],[[\"class\"],[\"flex items-center link purple mr4\"]],[[\"default\"],[[[[1,\"      \"],[8,[39,1],null,[[\"@name\",\"@size\",\"@iconClass\"],[\"element-28\",28,\"malibu-fill-gradient-purple mr2 mr0-ns\"]],null],[1,\"\\n      \"],[10,0],[14,0,\"dn dib-ns f4 ph2\"],[12],[1,\"Add-ons\"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[39,2],null,[[\"class\",\"route\"],[\"flex items-center link purple mr4\",\"buildpacks\"]],[[\"default\"],[[[[1,\"      \"],[8,[39,1],null,[[\"@name\",\"@size\",\"@iconClass\"],[\"buildpack-28\",28,\"malibu-fill-gradient-purple mr2 mr0-ns\"]],null],[1,\"\\n      \"],[10,0],[14,0,\"dn dib-ns f4 ph2\"],[12],[1,\"Buildpacks\"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n    \"],[10,0],[14,0,\"flex-auto\"],[12],[13],[1,\"\\n    \"],[10,0],[14,1,\"wormhole-destination-create-buildpack\"],[14,0,\"flex items-center\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"flex flex-auto\"],[12],[1,\"\\n  \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"link-to-external\",\"malibu-icon\",\"link-to\",\"component\",\"-outlet\"]]",
    "moduleName": "buildpack-registry/templates/buildpacks.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});