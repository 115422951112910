define("buildpack-registry/engine", ["exports", "ember-engines/engine", "ember-resolver", "ember-load-initializers", "buildpack-registry/config/environment"], function (_exports, _engine, _emberResolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    modulePrefix
  } = _environment.default;
  const Eng = _engine.default.extend({
    modulePrefix,
    Resolver: _emberResolver.default,
    dependencies: {
      externalRoutes: ['index'],
      services: ['current-user', 'flash-messages', 'router', 'session', 'store']
    }
  });
  (0, _emberLoadInitializers.default)(Eng, modulePrefix);
  var _default = Eng;
  _exports.default = _default;
});