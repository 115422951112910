define("buildpack-registry/validators/namespace-available", ["exports", "rsvp", "@ember/utils", "@ember/object"], function (_exports, _rsvp, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateNamespaceAvailable;
  function validateNamespaceAvailable(_ref) {
    let {
      store
    } = _ref;
    return (key, newValue, oldValue, changes, content) => {
      return new _rsvp.Promise(resolve => {
        // Let validatePresence catch this.
        if ((0, _utils.isEmpty)(newValue)) {
          return resolve(true);
        }

        // No change to the original namespace.
        if (newValue === oldValue) {
          return resolve(true);
        }
        const owner = (0, _object.get)(changes, 'owner') || (0, _object.get)(content, 'owner');
        const ownerHasNamespace = (0, _object.get)(owner, 'hasNamespace');
        if (ownerHasNamespace) {
          // Namespace is set via owner.
          const ownerNamespace = (0, _object.get)(owner, 'namespace');
          return resolve(ownerNamespace === newValue || 'Namespace is invalid');
        } else {
          // Validate that the namespace is available.
          return store.query('buildpack', {
            namespace: newValue
          }).then(results => {
            return resolve(results.toArray().length === 0 || 'Namespace is not available');
          });
        }
      });
    };
  }
});