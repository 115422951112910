define("buildpack-registry/templates/components/field-validations", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "VhqcxW9J",
    "block": "[[[18,1,[[28,[37,1],null,[[\"value\",\"isValidating\",\"isValid\",\"errors\",\"errorsList\"],[[33,2],[33,3],[33,4],[33,5],[50,\"validation-errors-list\",0,null,[[\"validationErrors\",\"property\"],[[33,5],[33,7]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"value\",\"isValidating\",\"isValid\",\"validationErrors\",\"component\",\"property\"]]",
    "moduleName": "buildpack-registry/templates/components/field-validations.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});