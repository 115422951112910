define("ember-data-factory-guy/mocks/mock-reload-request", ["exports", "ember-data-factory-guy/mocks/mock-find-record-request"], function (_exports, _mockFindRecordRequest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MockReloadRequest extends _mockFindRecordRequest.default {
    constructor(modelName) {
      super(modelName);
      this.setValidReturnsKeys(['attrs', 'json', 'headers']);
    }
  }
  _exports.default = MockReloadRequest;
});