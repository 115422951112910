define("buildpack-registry/components/github-linker", ["exports", "@ember/service", "@ember/component", "@ember/object"], function (_exports, _service, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Don't want to change this too much since it's copied over from Dashboard.
  /* eslint ember/order-in-components: "off" */

  const {
    mapBy,
    oneWay,
    filterBy,
    sort
  } = _object.computed;
  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    classNames: 'github-linker',
    user: null,
    repos: (0, _object.computed)(function () {
      return [];
    }),
    orgs: (0, _object.computed)(function () {
      return [];
    }),
    searchPublicRepos: true,
    searchPrivateRepos: true,
    searchTerm: '',
    selectedOwnerLogin: oneWay('user.login'),
    selectedOwner: (0, _object.computed)('owners.@each.login', 'selectedOwnerLogin', function () {
      return this.get('owners').findBy('login', this.get('selectedOwnerLogin'));
    }),
    orgSorting: ['login'],
    sortedOrgs: sort('orgs', 'orgSorting'),
    owners: (0, _object.computed)('user', 'sortedOrgs.[]', function () {
      return [this.get('user')].concat(this.get('sortedOrgs')).compact();
    }),
    ownerLogins: mapBy('owners', 'login'),
    hasTooManyOrgs: filterBy('orgs', 'hasMore', true),
    thirdPartyUrl: (0, _object.computed)(function () {
      return `https://github.com/settings/connections/applications`;
    }),
    queryParams: (0, _object.computed)('searchPublicRepos', 'searchPrivateRepos', 'selectedOwnerLogin', 'searchTerm', function () {
      let queryString = `fork:true user:${this.get('selectedOwnerLogin')} ${this.get('searchTerm')}`;
      if (this.get('searchPublicRepos')) {
        queryString = `is:public ${queryString}`;
      }
      if (this.get('searchPrivateRepos')) {
        queryString = `is:private ${queryString}`;
      }
      return {
        q: queryString
      };
    }),
    actions: {
      search() {
        this.set('repos', []);
        this.set('lastSearchTerm', null);
        this.get('store').query('github-linker-repo', this.get('queryParams')).then(repos => {
          const lastSearchTerm = `${this.get('selectedOwnerLogin')}/${this.get('searchTerm')}`;
          this.set('lastSearchTerm', lastSearchTerm);
          this.set('repos', repos.toArray());
        });
      }
    }
  });
  _exports.default = _default;
});