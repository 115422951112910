define("ember-data-factory-guy/payload/json-payload", ["exports", "@ember/utils", "ember-data-factory-guy/payload/base-payload"], function (_exports, _utils, _basePayload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _basePayload.default {
    /** 
     Can't add to included array for JSON payloads since they have
     no includes or sideloaded relationships
      Meta not working at the moment for this serializer even though
     it is being included here in the payload
     */
    add(more) {
      if (more.meta) {
        this.addMeta(more.meta);
      }
      return this.json;
    }
    getObjectKeys(key) {
      let attrs = this.json;
      if ((0, _utils.isEmpty)(key)) {
        return JSON.parse(JSON.stringify(attrs));
      }
      return attrs[key];
    }
    getListKeys(key) {
      let attrs = this.json;
      if ((0, _utils.isEmpty)(key)) {
        return JSON.parse(JSON.stringify(attrs));
      }
      if (typeof key === 'number') {
        return attrs[key];
      }
      if (key === 'firstObject') {
        return attrs[0];
      }
      if (key === 'lastObject') {
        return attrs[attrs.length - 1];
      }
    }
  }
  _exports.default = _default;
});