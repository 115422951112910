define("ember-data-factory-guy/builder/json-fixture-builder", ["exports", "ember-data-factory-guy/builder/fixture-builder", "ember-data-factory-guy/converter/json-fixture-converter", "ember-data-factory-guy/payload/json-payload"], function (_exports, _fixtureBuilder, _jsonFixtureConverter, _jsonPayload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   Fixture Builder for JSONSerializer
  
   */
  class JSONFixtureBuilder extends _fixtureBuilder.default {
    constructor(store) {
      super(store, _jsonFixtureConverter.default, _jsonPayload.default);
    }
    /**
     Map single object to response json.
      Allows custom serializing mappings and meta data to be added to requests.
      @param {String} modelName model name
     @param {Object} json Json object from record.toJSON
     @return {Object} responseJson
     */
    normalize(_, payload) {
      return payload;
    }
  }
  _exports.default = JSONFixtureBuilder;
});