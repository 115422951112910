define("buildpack-registry/components/buildpack-list-type", ["exports", "@ember/component", "@ember/object/computed", "ember-group-by"], function (_exports, _component, _computed, _emberGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    tagName: '',
    buildpacksByOwner: (0, _emberGroupBy.default)('buildpacks', 'ownerName'),
    hasBuildpacks: (0, _computed.notEmpty)('buildpacksByOwner')
  });
  _exports.default = _default;
});