define("ember-data-factory-guy/index", ["exports", "ember-data-factory-guy/factory-guy", "ember-data-factory-guy/mocks/exposed-request-functions", "ember-data-factory-guy/utils/manual-setup", "ember-data-factory-guy/builder/jsonapi-fixture-builder", "ember-data-factory-guy/builder/rest-fixture-builder", "ember-data-factory-guy/builder/json-fixture-builder", "ember-data-factory-guy/scenario"], function (_exports, _factoryGuy, _exposedRequestFunctions, _manualSetup, _jsonapiFixtureBuilder, _restFixtureBuilder, _jsonFixtureBuilder, _scenario) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "JSONAPIFixtureBuilder", {
    enumerable: true,
    get: function () {
      return _jsonapiFixtureBuilder.default;
    }
  });
  Object.defineProperty(_exports, "JSONFixtureBuilder", {
    enumerable: true,
    get: function () {
      return _jsonFixtureBuilder.default;
    }
  });
  Object.defineProperty(_exports, "RESTFixtureBuilder", {
    enumerable: true,
    get: function () {
      return _restFixtureBuilder.default;
    }
  });
  Object.defineProperty(_exports, "Scenario", {
    enumerable: true,
    get: function () {
      return _scenario.default;
    }
  });
  Object.defineProperty(_exports, "attributesFor", {
    enumerable: true,
    get: function () {
      return _factoryGuy.attributesFor;
    }
  });
  Object.defineProperty(_exports, "build", {
    enumerable: true,
    get: function () {
      return _factoryGuy.build;
    }
  });
  Object.defineProperty(_exports, "buildList", {
    enumerable: true,
    get: function () {
      return _factoryGuy.buildList;
    }
  });
  _exports.default = void 0;
  Object.defineProperty(_exports, "getPretender", {
    enumerable: true,
    get: function () {
      return _exposedRequestFunctions.getPretender;
    }
  });
  Object.defineProperty(_exports, "make", {
    enumerable: true,
    get: function () {
      return _factoryGuy.make;
    }
  });
  Object.defineProperty(_exports, "makeList", {
    enumerable: true,
    get: function () {
      return _factoryGuy.makeList;
    }
  });
  Object.defineProperty(_exports, "makeNew", {
    enumerable: true,
    get: function () {
      return _factoryGuy.makeNew;
    }
  });
  Object.defineProperty(_exports, "manualSetup", {
    enumerable: true,
    get: function () {
      return _manualSetup.default;
    }
  });
  Object.defineProperty(_exports, "mock", {
    enumerable: true,
    get: function () {
      return _exposedRequestFunctions.mock;
    }
  });
  Object.defineProperty(_exports, "mockCreate", {
    enumerable: true,
    get: function () {
      return _exposedRequestFunctions.mockCreate;
    }
  });
  Object.defineProperty(_exports, "mockDelete", {
    enumerable: true,
    get: function () {
      return _exposedRequestFunctions.mockDelete;
    }
  });
  Object.defineProperty(_exports, "mockFind", {
    enumerable: true,
    get: function () {
      return _exposedRequestFunctions.mockFind;
    }
  });
  Object.defineProperty(_exports, "mockFindAll", {
    enumerable: true,
    get: function () {
      return _exposedRequestFunctions.mockFindAll;
    }
  });
  Object.defineProperty(_exports, "mockFindRecord", {
    enumerable: true,
    get: function () {
      return _exposedRequestFunctions.mockFindRecord;
    }
  });
  Object.defineProperty(_exports, "mockLinks", {
    enumerable: true,
    get: function () {
      return _exposedRequestFunctions.mockLinks;
    }
  });
  Object.defineProperty(_exports, "mockQuery", {
    enumerable: true,
    get: function () {
      return _exposedRequestFunctions.mockQuery;
    }
  });
  Object.defineProperty(_exports, "mockQueryRecord", {
    enumerable: true,
    get: function () {
      return _exposedRequestFunctions.mockQueryRecord;
    }
  });
  Object.defineProperty(_exports, "mockReload", {
    enumerable: true,
    get: function () {
      return _exposedRequestFunctions.mockReload;
    }
  });
  Object.defineProperty(_exports, "mockSetup", {
    enumerable: true,
    get: function () {
      return _exposedRequestFunctions.mockSetup;
    }
  });
  Object.defineProperty(_exports, "mockTeardown", {
    enumerable: true,
    get: function () {
      return _exposedRequestFunctions.mockTeardown;
    }
  });
  Object.defineProperty(_exports, "mockUpdate", {
    enumerable: true,
    get: function () {
      return _exposedRequestFunctions.mockUpdate;
    }
  });
  _exports.setupFactoryGuy = setupFactoryGuy;
  var _default = _factoryGuy.default;
  _exports.default = _default;
  function setupFactoryGuy(hooks) {
    hooks.beforeEach(function () {
      (0, _manualSetup.default)(this);
    });
  }
});