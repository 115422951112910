define("ember-data-factory-guy/mocks/mock-delete-request", ["exports", "ember-data-factory-guy/factory-guy", "ember-data-factory-guy/mocks/mock-store-request", "ember-data-factory-guy/mocks/maybe-id-url-match"], function (_exports, _factoryGuy, _mockStoreRequest, _maybeIdUrlMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MockDeleteRequest extends (0, _maybeIdUrlMatch.default)(_mockStoreRequest.default) {
    constructor(modelName) {
      let {
        id,
        model
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      super(modelName, 'deleteRecord');
      this.id = id;
      this.model = model;
      this.setupHandler();
    }
    getType() {
      return "DELETE";
    }

    /**
     * Create fake snaphot with adapterOptions and record.
     *
     * Override the parent to find the model in the store if there is
     * an id available
     *
     * @returns {{adapterOptions: (*|Object), record: (*|DS.Model)}}
     */
    makeFakeSnapshot() {
      let snapshot = super.makeFakeSnapshot();
      if (this.id && !this.model) {
        snapshot.record = _factoryGuy.default.store.peekRecord(this.modelName, this.id);
      }
      return snapshot;
    }
  }
  _exports.default = MockDeleteRequest;
});