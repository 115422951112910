define("buildpack-registry/components/validation-errors-list", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    tagName: '',
    hasValidationErrors: (0, _computed.bool)('validationErrors'),
    validationErrorMessages: (0, _computed.alias)('validationErrors.validation')
  });
  _exports.default = _default;
});