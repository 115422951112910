define("ember-data-factory-guy/scenario", ["exports", "ember-data-factory-guy"], function (_exports, fgMethods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let proxyFx = ['make', 'makeNew', 'makeList', 'build', 'buildList', 'mockFind', 'mockFindRecord', 'mockFindAll', 'mockReload', 'mockQuery', 'mockQueryRecord', 'mockUpdate', 'mockCreate', 'mockDelete', 'mock', 'attributesFor'];
  class _default {
    constructor() {
      proxyFx.forEach(fx => this[fx] = fgMethods[fx]);
      this.store = fgMethods.default.store;
    }
    static settings() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      fgMethods.default.settings(opts);
    }
    run() {}
    include(scenarios) {
      (scenarios || []).forEach(Scenario => new Scenario().run());
    }
  }
  _exports.default = _default;
});