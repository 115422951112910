define("buildpack-registry/templates/buildpacks/buildpack-edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "AS980cjU",
    "block": "[[[10,0],[14,0,\"ph4 pv6 flex-auto\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"spacer w-100 center next-steps-copy\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"f2 mb6 dark-gray\"],[12],[1,\"Edit Buildpack\"],[13],[1,\"\\n\\n    \"],[1,[28,[35,0],null,[[\"buildpack\"],[[33,1,[\"buildpack\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"buildpack-form\",\"model\"]]",
    "moduleName": "buildpack-registry/templates/buildpacks/buildpack-edit.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});