define("ember-data-factory-guy/converter/active-model-fixture-converter", ["exports", "ember-data-factory-guy/converter/rest-fixture-converter"], function (_exports, _restFixtureConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   Convert base fixture to the ActiveModel Serializer expected payload.
   */
  class AMSFixtureConverter extends _restFixtureConverter.default {
    /**
     * In `serializeMode` use convert a relationship from "company" to "company_id"
     * which REST / JSON converters override to strip that _id
     *
     * @param relationship
     * @returns {*}
     */
    transformRelationshipKey(relationship) {
      if (this.serializeMode) {
        let transformFn = this.getTransformKeyFunction(relationship.type, 'Relationship');
        return transformFn(relationship.key, relationship.kind);
      } else {
        return super.transformRelationshipKey(relationship);
      }
    }
  }
  _exports.default = AMSFixtureConverter;
});