define("ember-data-factory-guy/utils/load-factories", ["exports", "ember-data-factory-guy/utils/helper-functions"], function (_exports, _helperFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  const factoryFileRegExp = new RegExp('/tests/factories');
  function _default() {
    (0, _helperFunctions.requireFiles)(factoryFileRegExp);
  }
});