define("ember-data-factory-guy/utils/manual-setup", ["exports", "ember-data-factory-guy/factory-guy", "ember-data-factory-guy/utils/load-factories", "ember-data-factory-guy/utils/load-scenarios"], function (_exports, _factoryGuy, _loadFactories, _loadScenarios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  // For manually setting up FactoryGuy in tests where the application is not started

  function _default(scope) {
    let owner = scope.owner || scope.container && scope.container.owner || scope;
    _factoryGuy.default.reset(); // redundant, but can't hurt
    _factoryGuy.default.setStore(owner.lookup('service:store'));
    (0, _loadFactories.default)();
    (0, _loadScenarios.default)(owner);
  }
});