define("@heroku/ember-malibu-icon/templates/components/malibu-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "OTY2UC7J",
    "block": "[[[11,\"svg\"],[16,5,[30,0,[\"style\"]]],[16,1,[30,0,[\"svgID\"]]],[16,0,[29,[\"icon malibu-icon \",[30,1]]]],[16,\"data-test-icon-name\",[30,2]],[16,\"aria-labelledby\",[52,[30,3],[30,0,[\"labelID\"]]]],[16,\"data-test-target\",[52,[30,4],[30,4],\"malibu-icon\"]],[16,\"aria-hidden\",[52,[30,5],[30,5],\"false\"]],[16,\"role\",[52,[30,6],[30,6],\"img\"]],[17,7],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[10,\"title\"],[15,1,[30,0,[\"labelID\"]]],[12],[1,[30,3]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,\"use\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[15,\"xlink:href\",[29,[\"#\",[30,2]]],\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@iconClass\",\"@name\",\"@title\",\"@data-test-target\",\"@aria-hidden\",\"@role\",\"&attrs\"],false,[\"if\"]]",
    "moduleName": "@heroku/ember-malibu-icon/templates/components/malibu-icon.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});