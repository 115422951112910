define("ember-data-factory-guy/builder/drf-fixture-builder", ["exports", "ember-data-factory-guy/builder/fixture-builder", "ember-data-factory-guy/converter/drf-fixture-converter", "ember-data-factory-guy/payload/drf-payload"], function (_exports, _fixtureBuilder, _drfFixtureConverter, _drfPayload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   Fixture Builder for DjangoRESTSerializer
   */
  class DRFFixtureBuilder extends _fixtureBuilder.default {
    constructor(store) {
      super(store, _drfFixtureConverter.default, _drfPayload.default);
    }

    /**
     DRFAdapter converts the errors to a JSONAPI error format for you,
     but the error HAS to have a status of 400 .. but WHY?
      @param errors
     @returns {*}
     */
    convertResponseErrors(errors, status) {
      if (status === 400) {
        return errors;
      } else {
        return super.convertResponseErrors(errors, status);
      }
    }
  }
  _exports.default = DRFFixtureBuilder;
});