define("buildpack-registry/templates/components/github-connected-repo", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "FciF70vM",
    "block": "[[[10,0],[14,0,\"flex items-center\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@name\",\"@size\",\"@iconClass\"],[\"service-github-16\",28,\"mr2\"]],null],[1,\"\\n  \"],[10,3],[15,6,[36,1]],[14,0,\"f4 purple\"],[14,\"target\",\"_blank\"],[14,\"data-test-target\",\"github-repo-full-name\"],[12],[1,[34,2]],[1,\"/\"],[1,[34,3]],[13],[1,\"\\n\"],[41,[33,5],[[[1,\"    \"],[10,0],[14,0,\"flex-auto tr\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"hk-button-sm--danger\"],[24,\"data-test-target\",\"github-repo-unlink-button\"],[4,[38,6],[[30,0],[33,7]],null],[12],[1,\"\\n        Disconnect\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[],false,[\"malibu-icon\",\"repoUrl\",\"repoOwner\",\"repoName\",\"if\",\"allowUnlink\",\"action\",\"onRepoUnlink\"]]",
    "moduleName": "buildpack-registry/templates/components/github-connected-repo.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});