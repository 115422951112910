define("buildpack-registry/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _routes.default)(function () {
    this.route('buildpacks', {
      path: '/'
    }, function () {
      this.route('buildpack-new', {
        path: 'new'
      });
      this.route('buildpack-edit', {
        path: ':buildpack_id/edit'
      });
      this.route('buildpack-publish', {
        path: ':buildpack_id/publish'
      });
      this.route('buildpack-publish-success', {
        path: ':buildpack_id/publish/success/:revision_id'
      });
    });
  });
  _exports.default = _default;
});