define("ember-data-factory-guy/mocks/mock-get-request", ["exports", "@ember/debug", "@ember/utils", "@ember/array", "@ember/polyfills", "ember-data-factory-guy/factory-guy", "ember-data/model", "ember-data-factory-guy/mocks/mock-store-request"], function (_exports, _debug, _utils, _array, _polyfills, _factoryGuy, _model, _mockStoreRequest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MockGetRequest extends _mockStoreRequest.default {
    constructor(modelName, requestType) {
      let {
        defaultResponse,
        queryParams
      } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      super(modelName, requestType);
      this.queryParams = queryParams;
      if (defaultResponse !== undefined) {
        this.setResponseJson(this.fixtureBuilder.convertForBuild(modelName, defaultResponse));
      }
      this.validReturnsKeys = [];
    }

    /**
     Used for inspecting the response that this mock will generate
      Usually the args will be an attribute like 'id', but it might
     also be a number like 0 or 1 for and index to list types.
      Ideally the responseJson is a JSONProxy class so the logic can be handed off there.
     Otherwise it's a plain object which is rare ( so the logic is not great )
      @param args
     @returns {*}
     */
    get(args) {
      let json = this.responseJson;
      if (json) {
        if (json.get) {
          return json.get(args);
        }
        // if this becomes issue, make this search more robust
        return json[args];
      }
    }
    setValidReturnsKeys(validKeys) {
      this.validReturnsKeys = validKeys;
    }
    validateReturnsOptions(options) {
      const responseKeys = Object.keys(options);
      (true && !(responseKeys.length === 1) && (0, _debug.assert)(`[ember-data-factory-guy] You can pass one key to 'returns',
                you passed these keys: ${responseKeys}`, responseKeys.length === 1));
      const [responseKey] = responseKeys;
      (true && !(this.validReturnsKeys.includes(responseKey)) && (0, _debug.assert)(`[ember-data-factory-guy] You passed an invalid keys for 'returns' function.
      Valid keys are ${this.validReturnsKeys}. You used this invalid key: ${responseKey}`, this.validReturnsKeys.includes(responseKey)));
      return responseKey;
    }
    returns() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let responseKey = this.validateReturnsOptions(options);
      this.setReturns(responseKey, options);
      return this;
    }
    setReturns(responseKey, options) {
      let json,
        model,
        models,
        modelName = this.modelName;
      switch (responseKey) {
        case 'id':
          // if you want to return existing model with an id, set up the json
          // as if it might be found, but check later during request match to
          // see if it really exists
          json = {
            id: options.id
          };
          this.idSearch = true;
          this.setResponseJson(this.fixtureBuilder.convertForBuild(modelName, json));
          break;
        case 'model':
          model = options.model;
          (true && !(model instanceof _model.default) && (0, _debug.assert)(`[ember-data-factory-guy] argument ( model ) must be a Model instance - found type:'
          ${(0, _utils.typeOf)(model)}`, model instanceof _model.default));
          json = {
            id: model.id
          };
          this.setResponseJson(this.fixtureBuilder.convertForBuild(modelName, json));
          break;
        case 'ids':
          {
            const store = _factoryGuy.default.store;
            models = options.ids.map(id => store.peekRecord(modelName, id));
            this.returns({
              models
            });
            break;
          }
        case 'models':
          {
            models = options.models;
            (true && !((0, _array.isArray)(models)) && (0, _debug.assert)(`[ember-data-factory-guy] argument ( models ) must be an array - found type:'
          ${(0, _utils.typeOf)(models)}`, (0, _array.isArray)(models)));
            json = models.map(model => ({
              id: model.id
            }));
            json = this.fixtureBuilder.convertForBuild(modelName, json);
            this.setResponseJson(json);
            break;
          }
        case 'json':
          json = options.json;
          if (!json.get) {
            // need to wrap a payload so the json can at least respond to 'get' method
            this.fixtureBuilder.wrapPayload(modelName, json);
          }
          this.setResponseJson(json);
          break;
        case 'attrs':
          {
            let currentId = this.responseJson.get('id'),
              modelParams = (0, _polyfills.assign)({
                id: currentId
              }, options.attrs);
            json = this.fixtureBuilder.convertForBuild(modelName, modelParams);
            this.setResponseJson(json);
            break;
          }
        case 'headers':
          this.addResponseHeaders(options.headers);
          break;
      }
    }
    setResponseJson(json) {
      this.responseJson = json;
      this.setupHandler();
    }
  }
  var _default = MockGetRequest;
  _exports.default = _default;
});