define("buildpack-registry/components/buildpack-list", ["exports", "@ember/array", "@ember/component", "@ember/object", "ember-group-by"], function (_exports, _array, _component, _object, _emberGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    _buildpacksGroupedByType: (0, _emberGroupBy.default)('buildpacks', 'ownerType'),
    enterpriseAccountBuildpacks: (0, _object.computed)('_buildpacksGroupedByType', function () {
      return this._buildpacksByType('enterprise-account');
    }),
    teamBuildpacks: (0, _object.computed)('_buildpacksGroupedByType', function () {
      return this._buildpacksByType('team');
    }),
    userBuildpacks: (0, _object.computed)('_buildpacksGroupedByType', function () {
      return this._buildpacksByType('user');
    }),
    _buildpacksByType(type) {
      const groups = this.get('_buildpacksGroupedByType');
      const group = groups.find(g => g.value === type) || {
        items: (0, _array.A)([])
      };
      return group.items.sortBy('owner.name', 'name');
    }
  });
  _exports.default = _default;
});