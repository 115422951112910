define("ember-data-factory-guy/builder/jsonapi-fixture-builder", ["exports", "ember-data-factory-guy/builder/fixture-builder", "ember-data-factory-guy/converter/jsonapi-fixture-converter", "ember-data-factory-guy/payload/json-api-payload"], function (_exports, _fixtureBuilder, _jsonapiFixtureConverter, _jsonApiPayload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   Fixture Builder for JSONAPISerializer
   */
  class JSONAPIFixtureBuilder extends _fixtureBuilder.default {
    constructor(store) {
      super(store, _jsonapiFixtureConverter.default, _jsonApiPayload.default);
      this.updateHTTPMethod = 'PATCH';
    }
  }
  _exports.default = JSONAPIFixtureBuilder;
});