define("ember-data-factory-guy/payload/drf-payload", ["exports", "@ember/utils", "@ember/polyfills", "ember-data-factory-guy/payload/json-payload"], function (_exports, _utils, _polyfills, _jsonPayload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _jsonPayload.default {
    // only add the meta data if there is query ( results key is present )
    addMeta(data) {
      if (this.json.results) {
        (0, _polyfills.assign)(this.json, data);
      }
    }
    getListKeys(key) {
      let attrs = this.json.results;
      if ((0, _utils.isEmpty)(key)) {
        return JSON.parse(JSON.stringify(attrs));
      }
      if (typeof key === 'number') {
        return attrs[key];
      }
      if (key === 'firstObject') {
        return attrs[0];
      }
      if (key === 'lastObject') {
        return attrs[attrs.length - 1];
      }
    }
  }
  _exports.default = _default;
});