define("buildpack-registry/components/buildpack-publish", ["exports", "@ember/component", "buildpack-registry/templates/components/buildpack-publish", "@ember/object", "@ember/service"], function (_exports, _component, _buildpackPublish, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    flashMessages: (0, _service.inject)(),
    store: (0, _service.inject)(),
    router: (0, _service.inject)(),
    tagName: '',
    layout: _buildpackPublish.default,
    selectedRef: (0, _object.computed)('buildpack.{defaultBranch,tags.[],tags.isFulfilled}', function () {
      let ref;
      if (this.get('buildpack.defaultBranch')) {
        ref = this.get('buildpack.defaultBranch');
      } else if (this.get('buildpack.tags.isFulfilled') && this.get('buildpack.tags').toArray().length > 0) {
        ref = this.get('buildpack.tags.firstObject');
      }
      return ref;
    }),
    groupedRefs: (0, _object.computed)('buildpack.{defaultBranch,tags.[],tags.isFulfilled}', function () {
      const refs = [];
      if (this.get('buildpack.defaultBranch')) {
        refs.push({
          groupName: 'Default Branch',
          options: [this.get('buildpack.defaultBranch')]
        });
      }
      if (this.get('buildpack.tags.isFulfilled') && this.get('buildpack.tags').toArray().length > 0) {
        refs.push({
          groupName: 'Recent Tags',
          options: this.get('buildpack.tags').toArray()
        });
      }
      return refs;
    }),
    actions: {
      publishBuildpackRevision(buildpack, refName, twoFactorToken) {
        const store = this.get('store');
        const revision = store.createRecord('buildpack-revision', {
          buildpack,
          ref: refName
        });
        return revision.saveWithTwoFactorToken(twoFactorToken).then(() => {
          this.get('flashMessages').success('Selected revision has been published.');
          return this.get('afterPublish')(buildpack, revision);
        });
      },
      setSelectedRef(ref) {
        this.set('selectedRef', ref);
      }
    }
  });
  _exports.default = _default;
});