define("buildpack-registry/components/buildpack-form", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "ember-changeset", "ember-changeset-validations", "buildpack-registry/validations/buildpack"], function (_exports, _component, _object, _computed, _service, _utils, _emberChangeset, _emberChangesetValidations, _buildpack) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CATEGORIES = [{
    key: 'languages',
    name: 'Languages/Frameworks'
  }, {
    key: 'packages',
    name: 'Packages'
  }, {
    key: 'tools',
    name: 'Tools'
  }];
  const SUPPORT_METHODS = ['github', 'website', 'email', 'unsupported'];
  var _default = _component.default.extend({
    flashMessages: (0, _service.inject)('flash-messages'),
    router: (0, _service.inject)(),
    store: (0, _service.inject)(),
    isEditing: (0, _computed.not)('buildpack.isNew'),
    isNamespaceDisabled: (0, _computed.or)('isEditing', 'ownerHasNamespace', 'ownerIsEmpty'),
    logoSrc: (0, _computed.or)('changeset.{base64Logo,mediumLogoUrl}'),
    ownerHasNamespace: (0, _computed.notEmpty)('changeset.owner.namespace'),
    ownerIsEmpty: (0, _computed.empty)('changeset.owner'),
    buildpackUrl: (0, _object.computed)('changeset.{namespace,name}', function () {
      const namespace = this.get('changeset.namespace');
      const name = this.get('changeset.name');
      if ((0, _utils.isPresent)(namespace) && (0, _utils.isPresent)(name)) {
        return `${namespace}/${name}`;
      } else {
        return null;
      }
    }),
    changeset: (0, _object.computed)('buildpack', function () {
      const store = this.get('store');
      const buildpackValidations = _buildpack.default.create(store);
      const changeset = new _emberChangeset.default(this.get('buildpack'), (0, _emberChangesetValidations.default)(buildpackValidations), buildpackValidations, {
        skipValidate: true
      });
      this.setDefaultValues(changeset);
      return changeset;
    }),
    hasSourceRepo: (0, _object.computed)('changeset.{sourceOwner,sourceRepo}', function () {
      return (0, _utils.isPresent)(this.get('changeset.sourceOwner')) && (0, _utils.isPresent)(this.get('changeset.sourceRepo'));
    }),
    selectedBuildpackCategory: (0, _object.computed)(function () {
      return this.get('categories.firstObject');
    }),
    init() {
      this._super(...arguments);
      this.set('supportMethods', SUPPORT_METHODS);
      this.set('categories', CATEGORIES);
    },
    actions: {
      setBuildpackCategory(category) {
        this.set('changeset.category', category.key);
        this.set('selectedBuildpackCategory', category);
      },
      onFileCancel() {
        this.get('changeset').set('base64Logo', null);
      },
      onFileSelect(fileContents) {
        this.get('changeset').set('base64Logo', fileContents);
      },
      save() {
        const changeset = this.get('changeset');
        return changeset.validate().then(() => {
          if (changeset.get('isValid')) {
            return changeset.save().then(() => {
              return this.get('router').transitionTo('buildpack-registry.buildpacks.buildpack-publish', this.get('buildpack.id'));
            });
          }
        });
      },
      selectOwner(owner) {
        const namespace = (0, _object.get)(owner, 'namespace');
        this.set('changeset.owner', owner);
        this.set('changeset.namespace', namespace);
      },
      onRepoLink(repository) {
        this.set('changeset.sourceType', 'github');
        this.set('changeset.sourceOwner', repository.get('ownerName'));
        this.set('changeset.sourceRepo', repository.get('name'));
      },
      onRepoUnlink() {
        this.set('changeset.sourceType', '');
        this.set('changeset.sourceOwner', '');
        this.set('changeset.sourceRepo', '');
      },
      archive() {
        const confirmed = window.confirm('Archiving a buildpack will remove it from the Buildpack Registry, but will NOT break apps that currently use the buildpack. This action cannot be undone. Archive this buildpack?');
        if (confirmed) {
          this.get('buildpack').destroyRecord().then(() => {
            this.get('flashMessages').success('Buildpack Archived');
            return this.get('router').transitionTo('buildpack-registry.buildpacks');
          });
        }
      }
    },
    setDefaultValues(changeset) {
      if ((0, _utils.isBlank)(changeset.get('supportMethod'))) {
        changeset.set('supportMethod', SUPPORT_METHODS[0]);
      }
    }
  });
  _exports.default = _default;
});