define("ember-data-factory-guy/mocks/mock-request", ["exports", "@ember/debug", "@ember/polyfills", "ember-data-factory-guy/utils/helper-functions", "ember-data-factory-guy/factory-guy", "ember-data-factory-guy/mocks/request-manager"], function (_exports, _debug, _polyfills, _helperFunctions, _factoryGuy, _requestManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default {
    constructor() {
      this.status = 200;
      this.responseHeaders = {};
      this.responseJson = null;
      this.errorResponse = null;
      this.isDisabled = false;
      this.isDestroyed = false;
      this.timesCalled = 0;
    }

    /**
     * Set the adapter options that this mockCreate will be using
     *
     * @param {Object} options adapterOptions
     */
    withAdapterOptions(options) {
      this.adapterOptions = options;
      this.setupHandler();
      return this;
    }

    /**
     */
    get() {}

    /**
     * @returns {String}
     */
    getUrl() {}
    getType() {
      return 'GET';
    }
    returns( /*options = {}*/
    ) {}
    addResponseHeaders(headers) {
      (0, _polyfills.assign)(this.responseHeaders, headers);
    }
    succeeds() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.status = opts.status || 200;
      this.errorResponse = null;
      return this;
    }
    isErrorStatus(status) {
      return !!status.toString().match(/^([345]\d{2})/);
    }
    fails() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let convertErrors = opts.hasOwnProperty('convertErrors') ? opts.convertErrors : true,
        status = opts.status || 500,
        response = opts.response || null;
      (true && !(this.isErrorStatus(status)) && (0, _debug.assert)(`[ember-data-factory-guy] 'fails' method status code must be 3XX, 4XX or 5XX,
        you are using: ${status}`, this.isErrorStatus(status)));
      this.status = status;
      this.errorResponse = response;
      if (response && convertErrors) {
        let errors = this.fixtureBuilder.convertResponseErrors(response, this.status);
        this.errorResponse = errors;
      }
      return this;
    }

    // for a fails response, the response you might have set ( like a payload ) will
    // be discarded in favour of the error response that was built for you in fails method
    actualResponseJson() {
      let responseText = this.isErrorStatus(this.status) ? this.errorResponse : this.responseJson;
      return JSON.stringify(responseText);
    }
    getResponse() {
      return {
        responseText: this.actualResponseJson(),
        headers: this.responseHeaders,
        status: this.status
      };
    }
    logInfo() {
      if (_factoryGuy.default.logLevel > 0) {
        const json = JSON.parse(this.getResponse().responseText),
          name = this.constructor.name.replace('Request', ''),
          type = this.getType(),
          status = `[${this.status}]`,
          url = this.getUrl();
        let fullUrl = url;
        if (!(0, _helperFunctions.isEmptyObject)(this.queryParams)) {
          fullUrl = [url, '?', (0, _helperFunctions.param)(this.queryParams)].join('');
        }
        const info = ['[factory-guy]', name, type, status, fullUrl, json];
        console.log(...info);
      }
    }
    withParams(queryParams) {
      this.queryParams = queryParams;
      return this;
    }
    hasQueryParams() {
      return !(0, _helperFunctions.isEmptyObject)(this.queryParams);
    }
    withSomeParams(someQueryParams) {
      this.someQueryParams = someQueryParams;
      return this;
    }
    paramsMatch(request) {
      if (!(0, _helperFunctions.isEmptyObject)(this.someQueryParams)) {
        return (0, _helperFunctions.isPartOf)(request.queryParams, (0, _helperFunctions.toParams)(this.someQueryParams));
      }
      if (!(0, _helperFunctions.isEmptyObject)(this.queryParams)) {
        return (0, _helperFunctions.isEquivalent)(request.queryParams, (0, _helperFunctions.toParams)(this.queryParams));
      }
      return true;
    }
    extraRequestMatches(request) {
      return this.paramsMatch(request);
    }
    matches(request) {
      if (this.isDisabled) {
        return false;
      }
      if (!this.extraRequestMatches(request)) {
        return false;
      }
      this.timesCalled++;
      this.logInfo();
      if (this.useOnce) {
        this.disable();
      }
      return true;
    }

    // mockId holds the url for this mock request
    oldUrl() {
      return this.mockId && this.mockId.url;
    }
    changedUrl() {
      return this.getUrl() !== this.oldUrl();
    }
    setupHandler() {
      if (!this.mockId) {
        _requestManager.default.addHandler(this);
      } else if (this.changedUrl()) {
        _requestManager.default.replaceHandler(this);
      }
    }

    // once the mock is used, it will disable itself, so it can't be used again.
    // most useful when using mockCreate to make the same type of model
    // over and over again, and the returning id is different.
    singleUse() {
      this.useOnce = true;
    }
    disable() {
      this.isDisabled = true;
    }
    enable() {
      this.isDisabled = false;
    }
    destroy() {
      _requestManager.default.removeHandler(this);
      this.isDestroyed = true;
    }
  }
  _exports.default = _default;
});