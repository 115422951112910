define("buildpack-registry/templates/components/buildpack-owner-select-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8spBi7Zr",
    "block": "[[[6,[39,0],null,[[\"id\",\"selected\",\"onChange\",\"options\"],[\"buildpack-owner\",[33,1],[28,[37,2],[[30,0],\"setOwner\"],null],[30,0,[\"groupedOwners\"]]]],[[\"default\"],[[[[1,\"  \"],[41,[30,1,[\"hasEnterpriseAccount\"]],[[[1,\"     \"]],[]],null],[1,[28,[35,4],[[30,1,[\"name\"]],[30,1,[\"email\"]]],null]],[1,\"\\n\"]],[1]]]]]],[\"owner\"],false,[\"hk-select\",\"selectedOwner\",\"action\",\"if\",\"or\"]]",
    "moduleName": "buildpack-registry/templates/components/buildpack-owner-select-list.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});