define("buildpack-registry/routes/buildpacks/buildpack-publish", ["exports", "rsvp", "@ember/routing/route"], function (_exports, _rsvp, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    model(params) {
      const buildpack = this.get('store').find('buildpack', params.buildpack_id);
      return (0, _rsvp.hash)({
        buildpack
      });
    },
    afterModel(_ref) {
      let {
        buildpack
      } = _ref;
      return (0, _rsvp.hash)({
        branches: buildpack.get('branches'),
        tags: buildpack.get('tags'),
        revisions: buildpack.get('revisions')
      });
    },
    actions: {
      goToSuccess(buildpack, revision) {
        this.transitionTo('buildpacks.buildpack-publish-success', buildpack.get('id'), revision.get('id'));
      }
    }
  });
  _exports.default = _default;
});