define("ember-data-factory-guy/converter/rest-fixture-converter", ["exports", "@ember/string", "@ember/array", "ember-inflector", "ember-data-factory-guy/converter/json-fixture-converter"], function (_exports, _string, _array, _emberInflector, _jsonFixtureConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   Convert base fixture to a REST Serializer formatted payload.
  
   @param store
   @constructor
   */
  class RESTFixtureConverter extends _jsonFixtureConverter.default {
    constructor(store, options) {
      super(store, options);
      this.included = {};
    }
    emptyResponse(modelName) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return {
        [modelName]: options.useValue || null
      };
    }

    /**
     * RESTSerializer has a payload key
     *
     * @param modelName
     * @param fixture
     * @returns {*}
     */
    createPayload(modelName, fixture) {
      return {
        [this.getPayloadKey(modelName)]: fixture
      };
    }

    /**
     * Get the payload key for this model from the serializer
     *
     * @param modelName
     * @returns {*}
     */
    getPayloadKey(modelName) {
      let serializer = this.store.serializerFor(modelName),
        payloadKey = modelName;
      // model fragment serializer does not have payloadKeyFromModelName method
      if (serializer.payloadKeyFromModelName) {
        payloadKey = serializer.payloadKeyFromModelName(modelName);
      }
      return this.listType ? (0, _emberInflector.pluralize)(payloadKey) : payloadKey;
    }

    /**
     * Add the included data to the final payload
     *
     * @param payload
     */
    addIncludedArray(payload) {
      Object.keys(this.included).forEach(key => {
        if (!payload[key]) {
          payload[key] = this.included[key];
        } else {
          Array.prototype.push.apply(payload[key], this.included[key]);
        }
      });
    }

    /**
     Add the model to included array unless it's already there.
      @param {String} modelKey
     @param {Object} data
     @param {Object} includeObject
     */
    addToIncluded(data, modelKey) {
      let relationshipKey = (0, _emberInflector.pluralize)((0, _string.dasherize)(modelKey));
      if (!this.included[relationshipKey]) {
        this.included[relationshipKey] = [];
      }
      let modelRelationships = this.included[relationshipKey],
        found = (0, _array.A)(modelRelationships).find(existing => existing.id === data.id);
      if (!found) {
        modelRelationships.push(data);
      }
    }

    /**
     Add proxied json to this payload, by taking all included models and
     adding them to this payloads includes
      @param proxy json payload proxy
     */
    addToIncludedFromProxy(proxy) {
      proxy.includeKeys().forEach(modelKey => {
        let includedModels = proxy.getInclude(modelKey);
        includedModels.forEach(data => {
          this.addToIncluded(data, modelKey);
        });
      });
    }
  }
  _exports.default = RESTFixtureConverter;
});