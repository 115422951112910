define("ember-data-factory-guy/mocks/mock-query-record-request", ["exports", "ember-data-factory-guy/mocks/mock-get-request"], function (_exports, _mockGetRequest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MockQueryRecordRequest extends _mockGetRequest.default {
    /**
     * By default this query will return a payload of 'null' or no result
     *
     * @param modelName
     * @param queryParams
     */
    constructor(modelName) {
      let queryParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      super(modelName, 'queryRecord', {
        defaultResponse: null,
        queryParams
      });
      this.setValidReturnsKeys(['model', 'json', 'id', 'headers']);
    }
  }
  _exports.default = MockQueryRecordRequest;
});