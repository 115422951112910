define("buildpack-registry/components/github-connected-repo", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    allowUnlink: true,
    repoUrl: (0, _object.computed)('repoOwner', 'repoName', function () {
      return `https://github.com/${this.get('repoOwner')}/${this.get('repoName')}`;
    })
  });
  _exports.default = _default;
});