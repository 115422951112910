define("buildpack-registry/templates/components/validation-errors-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "5+P7fbsP",
    "block": "[[[41,[33,1],[[[1,\"\\t\"],[10,\"ul\"],[14,0,\"list pl0 dark-red\"],[15,\"data-test-target\",[28,[37,2],[\"validation-errors-\",[33,3]],null]],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[33,6]],null]],null],null,[[[1,\"  \\t\\t\"],[10,\"li\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"message\"],false,[\"if\",\"hasValidationErrors\",\"concat\",\"property\",\"each\",\"-track-array\",\"validationErrorMessages\"]]",
    "moduleName": "buildpack-registry/templates/components/validation-errors-list.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});