define("buildpack-registry/helpers/is-after", ["exports", "ember", "buildpack-registry/config/environment", "ember-moment/helpers/is-after"], function (_exports, _ember, _environment, _isAfter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _isAfter.default.extend({
    globalAllowEmpty: !!_ember.default.get(_environment.default, 'moment.allowEmpty')
  });
  _exports.default = _default;
});