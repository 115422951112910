define("buildpack-registry/templates/components/support-method-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "efKV/0Ba",
    "block": "[[[6,[39,0],null,[[\"radioId\",\"value\",\"groupValue\",\"classNames\",\"radioClass\"],[[28,[37,1],[\"buildpack-support-\",[33,2]],null],[33,2],[33,3],\"supportMethods pointer flex flex-auto mr2 v-mid\",\"mr2\"]],[[\"default\"],[[[[1,\"  \"],[46,[28,[37,1],[\"support-methods/\",[33,2]],null],null,[[\"changeset\",\"isSelected\",\"class\"],[[33,5],[33,6],\"w-100\"]],null],[1,\"\\n\"]],[]]]]]],[],false,[\"radio-button\",\"concat\",\"supportMethod\",\"groupValue\",\"component\",\"changeset\",\"isSelected\"]]",
    "moduleName": "buildpack-registry/templates/components/support-method-card.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});