define("buildpack-registry/validators/format-conditionally", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateFormatConditionally;
  function validateFormatConditionally() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return (key, newValue, oldValue, changes) => {
      if (shouldValidate(options, key, newValue, oldValue, changes)) {
        return (0, _validators.validateFormat)(options)(key, newValue);
      } else {
        return true;
      }
    };
  }
  function shouldValidate(options, key, newValue, oldValue, changes) {
    const conditional = options['if'];
    if (typeof conditional === 'function') {
      return conditional(key, newValue, oldValue, changes);
    } else {
      return true;
    }
  }
});