define("buildpack-registry/components/buildpack-owner-select-list", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "ember-group-by"], function (_exports, _component, _object, _computed, _emberGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    tagName: '',
    ownersSortingKey: ['enterpriseAccountName', 'name'],
    ownersGroupedByType: (0, _emberGroupBy.default)('sortedOwners', 'type'),
    teamsGroupedByEnterpriseAccount: (0, _emberGroupBy.default)('sortedOwners', 'enterpriseAccount.id'),
    sortedOwners: (0, _computed.sort)('owners', 'ownersSortingKey'),
    groupedOwners: (0, _object.computed)('enterpriseAccountOwners.[]', 'teamOwners.[]', 'userOwner', function () {
      const owners = [{
        groupName: 'Current account',
        options: [this.get('userOwner')]
      }];
      if (this.get('enterpriseAccountOwners')) {
        const enterpriseOptions = {
          groupName: 'Enterprise accounts and teams',
          options: this.get('enterpriseAccountOwners')
        };
        this.get('enterpriseAccountOwners').forEach(accountOwner => {
          if (accountOwner.get('teams')) {
            enterpriseOptions.options.push(...accountOwner.get('teams'));
          }
        });
        owners.push(enterpriseOptions);
      }
      if (this.get('teamOwners')) {
        owners.push({
          groupName: 'Heroku teams',
          options: this.get('teamOwners')
        });
      }
      return owners;
    }),
    // Enterprise accounts, each with a list of its teams.
    enterpriseAccountOwners: (0, _object.computed)('ownersGroupedByType', 'teamsGroupedByEnterpriseAccount', function () {
      const accounts = this.findGroupItems('enterprise-account', this.get('ownersGroupedByType'));
      const teamGroups = this.get('teamsGroupedByEnterpriseAccount');
      return accounts.map(account => {
        const teams = this.findGroupItems(account.get('id'), teamGroups);
        account.teams = teams;
        return account;
      });
    }),
    // Non-enterprise teams.
    teamOwners: (0, _object.computed)('ownersGroupedByType', function () {
      // Filter out enterprise teams.
      return this.findGroupItems('team', this.get('ownersGroupedByType')).filter(owner => !owner.get('hasEnterpriseAccount'));
    }),
    userOwner: (0, _object.computed)('ownersGroupedByType', function () {
      return this.findGroupItems('user', this.get('ownersGroupedByType'))[0];
    }),
    findGroupItems(type, groups) {
      const group = groups.find(g => String(g.value) === type) || {
        items: []
      };
      return group.items;
    },
    actions: {
      setOwner(owner) {
        this.set('selectedOwner', owner);
        this.get('selectOwner')(owner);
      }
    }
  });
  _exports.default = _default;
});