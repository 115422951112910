define("ember-data-factory-guy/mocks/mock-find-all-request", ["exports", "ember-data-factory-guy/mocks/mock-get-request"], function (_exports, _mockGetRequest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MockFindAllRequest extends _mockGetRequest.default {
    constructor(modelName) {
      super(modelName, 'findAll', {
        defaultResponse: []
      });
      this.setValidReturnsKeys(['models', 'json', 'ids', 'headers']);
    }
  }
  _exports.default = MockFindAllRequest;
});