define("buildpack-registry/templates/components/support-methods/unsupported", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ur6Era/F",
    "block": "[[[10,0],[14,0,\"f4 mb1 dark-gray\"],[12],[1,\"Unsupported\"],[13],[1,\"\\n\"],[10,0],[14,0,\"gray lh-copy\"],[12],[1,\"This is an unsupported buildpack\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "buildpack-registry/templates/components/support-methods/unsupported.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});