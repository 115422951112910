define("buildpack-registry/templates/components/buildpack-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vH4glSFI",
    "block": "[[[10,0],[14,0,\"dib w-100 mb2 pr2-ns\"],[14,\"data-test-target\",\"buildpack-list-item\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"class\",\"route\",\"model\"],[\"flex flex-column card pointer ba b--silver br1 link tc relative\",\"buildpacks.buildpack-publish\",[33,1,[\"id\"]]]],[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"pa4 pb5\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"w-100 tc mt1 mb2\"],[12],[1,\"\\n\"],[41,[33,1,[\"mediumLogoUrl\"]],[[[1,\"          \"],[10,\"img\"],[14,0,\"br1 w4 h4\"],[15,\"src\",[29,[[33,1,[\"mediumLogoUrl\"]]]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[39,3],null,[[\"@name\",\"@iconClass\",\"@size\"],[\"buildpack-28\",\"malibu-fill-gradient-purple w4 h4 o-50\",28]],null],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"f4-ns dark-gray truncate lh-copy mv1\"],[12],[1,[33,1,[\"name\"]]],[13],[1,\"\\n      \"],[10,0],[14,0,\"mid-gray mb2 h2 truncate\"],[12],[1,[33,1,[\"description\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"absolute pv1 bottom-1 left-1 right-1 purple\"],[12],[1,[33,1,[\"namespace\"]]],[13],[1,\"\\n\"]],[]]]]],[13],[1,\"\\n\"]],[],false,[\"link-to\",\"buildpack\",\"if\",\"malibu-icon\"]]",
    "moduleName": "buildpack-registry/templates/components/buildpack-card.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});