define("ember-data-factory-guy/sequence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(fn) {
    let index = 1;
    this.next = function () {
      return fn.call(this, index++);
    };
    this.reset = function () {
      index = 1;
    };
  }
});