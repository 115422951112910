define("buildpack-registry/validations/buildpack", ["exports", "ember-changeset-validations/validators", "buildpack-registry/validators/format-conditionally", "buildpack-registry/validators/name-available", "buildpack-registry/validators/namespace-available", "buildpack-registry/validators/github-repo"], function (_exports, _validators, _formatConditionally, _nameAvailable, _namespaceAvailable, _githubRepo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.create = create;
  function create(store) {
    return {
      category: (0, _validators.validatePresence)(true),
      name: [(0, _validators.validateLength)({
        min: 2,
        allowBlank: false
      }), (0, _validators.validateFormat)({
        regex: /^[a-z0-9-]*$/,
        message: 'Name should only contain lowercase letters, numbers, and dashes'
      }), (0, _nameAvailable.default)({
        store
      })],
      namespace: [(0, _validators.validateLength)({
        min: 2,
        allowBlank: false
      }), (0, _validators.validateFormat)({
        regex: /^[a-z0-9-]*$/,
        message: 'Namespace should only contain lowercase letters, numbers, and dashes'
      }), (0, _namespaceAvailable.default)({
        store
      })],
      supportMethod: (0, _validators.validatePresence)(true),
      supportEmail: (0, _formatConditionally.default)({
        type: 'email',
        if: (key, newValue, oldValue, changes) => {
          return changes.supportMethod === 'email';
        }
      }),
      supportWebsite: (0, _formatConditionally.default)({
        type: 'url',
        if: (key, newValue, oldValue, changes) => {
          return changes.supportMethod === 'website';
        }
      }),
      description: (0, _validators.validatePresence)({
        presence: true,
        message: "Short Description can't be blank"
      }),
      owner: (0, _validators.validatePresence)({
        presence: true
      }),
      githubRepo: (0, _githubRepo.default)({
        message: "GitHub Repository can't be blank"
      })
    };
  }
});