define("buildpack-registry/components/field-validations", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/runloop"], function (_exports, _component, _object, _computed, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    classNameBindings: 'hasValidationErrors:has-errors',
    debounceValidation: 0,
    isValidating: false,
    changesetErrors: (0, _computed.alias)('changeset.error'),
    hasValidationErrors: (0, _computed.bool)('validationErrors'),
    isValid: (0, _computed.empty)('validationErrors'),
    validationErrors: (0, _object.computed)('changesetErrors', function () {
      const errors = this.get('changesetErrors');
      const property = this.get('property');
      return (0, _object.get)(errors, property);
    }),
    value: (0, _object.computed)({
      get() {
        return this.get('changeset').get(this.get('property'));
      },
      set(key, value) {
        this.get('changeset').set(this.get('property'), value);
        this.set('isValidating', true);
        (0, _runloop.debounce)(this, 'validateProperty', this.get('debounceValidation'));
        return value;
      }
    }),
    validateProperty() {
      const changeset = this.get('changeset');
      const property = this.get('property');
      return changeset.validate(property).then(() => {
        this.set('isValidating', false);
      });
    }
  });
  _exports.default = _default;
});