define("ember-data-factory-guy/converter/json-fixture-converter", ["exports", "@ember/utils", "@ember/string", "ember-data-factory-guy/utils/helper-functions", "ember-data-factory-guy/converter/fixture-converter"], function (_exports, _utils, _string, _helperFunctions, _fixtureConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   Convert base fixture to a JSON format payload.
  
   @param store
   @constructor
   */
  class JSONFixtureConverter extends _fixtureConverter.default {
    constructor(store, options) {
      super(store, options);
      this.defaultKeyTransformFn = _string.underscore;
      this.polymorphicTypeTransformFn = _string.underscore;
    }

    /**
     * Can't add to payload since sideloading not supported
     *
     * @param moreJson
     */
    add( /*moreJson*/
    ) {}

    /**
     * There is no payload key for JSON Serializer
     *
     * @param modelName
     * @param fixture
     * @returns {*}
     */
    createPayload(_, fixture) {
      return fixture;
    }

    /**
     * There is no sideloading for JSON Serializer
     *
     * @param payload
     */
    addIncludedArray( /*payload*/
    ) {}

    /**
     Convert single record
      @param {String} modelName
     @param {Object} fixture
     */
    convertSingle(modelName, fixture) {
      let data = {},
        attributes = this.extractAttributes(modelName, fixture),
        relationships = this.extractRelationships(modelName, fixture);
      Object.keys(attributes).forEach(key => {
        data[key] = attributes[key];
      });
      Object.keys(relationships).forEach(key => {
        data[key] = relationships[key];
      });
      this.addPrimaryKey(modelName, data, fixture);
      this.verifyLinks(modelName, fixture.links);
      this.assignLinks(data, fixture.links);
      return data;
    }
    transformRelationshipKey(relationship) {
      let transformedKey = super.transformRelationshipKey(relationship);
      if (relationship.options.polymorphic) {
        transformedKey = transformedKey.replace('_id', '');
      }
      return transformedKey;
    }

    /**
      @param {Object} record
     @param {Object} relationship
     */
    normalizeAssociation(record, relationship) {
      if (this.serializeMode) {
        return record.id;
      }
      if ((0, _utils.typeOf)(record) === 'object') {
        if (relationship.options.polymorphic) {
          return {
            type: (0, _string.dasherize)(record.type),
            id: record.id
          };
        } else {
          return record.id;
        }
      }
      // it's a model instance
      if (relationship.options.polymorphic) {
        return {
          type: (0, _string.dasherize)(record.constructor.modelName),
          id: record.id
        };
      }
      return record.id;
    }

    /**
     * JSON/REST links can be placed in the data exactly as they appear
     * on the fixture definition
     *
     *   json = build('user', {links: {properties: '/user/1/properties'}});
     *
     *    {
     *      user: {
     *        id: 1,
     *        name: 'User1',
     *        style: "normal",
     *        links: { properties: '/user/1/properties' }
     *      }
     *    }
     *
     * @param data
     * @param links
     */
    assignLinks(data, links) {
      if (!(0, _helperFunctions.isEmptyObject)(links)) {
        data.links = links;
      }
    }

    /**
     The JSONSerializer does not support sideloading records
      @param {String} modelKey
     @param {Object} data
     @param {Object} includeObject
     */
    addToIncluded( /*data, modelKey*/
    ) {}

    /**
     The JSONSerializer does not support sideloading records
      @param proxy json payload proxy
     */
    addToIncludedFromProxy( /*proxy*/
    ) {}
  }
  _exports.default = JSONFixtureConverter;
});