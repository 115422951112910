define("ember-data-factory-guy/builder/fixture-builder-factory", ["exports", "require", "ember-data", "ember-data-factory-guy/builder/jsonapi-fixture-builder", "ember-data-factory-guy/builder/rest-fixture-builder", "ember-data-factory-guy/builder/json-fixture-builder", "ember-data-factory-guy/builder/drf-fixture-builder", "ember-data-factory-guy/builder/active-model-fixture-builder"], function (_exports, _require, _emberData, _jsonapiFixtureBuilder, _restFixtureBuilder, _jsonFixtureBuilder, _drfFixtureBuilder, _activeModelFixtureBuilder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let ActiveModelSerializer, DjangoSerializer;
  try {
    let activeModel = (0, _require.default)("active-model-adapter");
    ActiveModelSerializer = activeModel.ActiveModelSerializer;
  } catch (e) {
    // do nothing
  }
  try {
    let drf = (0, _require.default)("ember-django-adapter/serializers/drf");
    DjangoSerializer = drf && drf.default;
  } catch (e) {
    // do nothing
  }
  class _default {
    constructor(store) {
      this.store = store;
    }

    /**
     Return appropriate FixtureBuilder for the model's serializer type
     */
    fixtureBuilder(modelName) {
      let serializer = this.store.serializerFor(modelName);
      if (!serializer) {
        return new _jsonapiFixtureBuilder.default(this.store);
      }
      if (this.usingJSONAPISerializer(serializer)) {
        return new _jsonapiFixtureBuilder.default(this.store);
      }
      if (this.usingDRFSerializer(serializer)) {
        return new _drfFixtureBuilder.default(this.store);
      }
      if (this.usingActiveModelSerializer(serializer)) {
        return new _activeModelFixtureBuilder.default(this.store);
      }
      if (this.usingRESTSerializer(serializer)) {
        return new _restFixtureBuilder.default(this.store);
      }
      if (this.usingJSONSerializer(serializer)) {
        return new _jsonFixtureBuilder.default(this.store);
      }
      return new _jsonapiFixtureBuilder.default(this.store);
    }
    usingJSONAPISerializer(serializer) {
      return serializer instanceof _emberData.default.JSONAPISerializer;
    }
    usingDRFSerializer(serializer) {
      return DjangoSerializer && serializer instanceof DjangoSerializer;
    }
    usingActiveModelSerializer(serializer) {
      return ActiveModelSerializer && serializer instanceof ActiveModelSerializer;
    }
    usingRESTSerializer(serializer) {
      return serializer instanceof _emberData.default.RESTSerializer;
    }
    usingJSONSerializer(serializer) {
      return serializer instanceof _emberData.default.JSONSerializer;
    }
  }
  _exports.default = _default;
});