define("buildpack-registry/routes/buildpacks/buildpack-new", ["exports", "@ember/routing/route", "rsvp", "partner-portal-ui/config/environment", "partner-portal-ui/utils/redirect"], function (_exports, _route, _rsvp, _environment, _redirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    beforeModel(transition) {
      return this.get('store').queryRecord('githubUser', {}).catch(() => {
        // TODO: do we also need to check if the user is valid (has a token or something?)
        // Redirect to the landing page on dashboard so this user can connect GitHub.
        transition.abort();
        const intendedLocation = `${window.location.protocol}//${window.location.host}/buildpacks/new`;
        const redirectTo = `${_environment.default.githubConnectLandingUrl}?sendTo=${intendedLocation}`;
        (0, _redirect.default)(redirectTo);
      });
    },
    model() {
      const store = this.get('store');
      const buildpack = store.createRecord('buildpack');
      const owners = store.query('buildpackOwner', {
        userId: '~'
      });
      const githubUser = store.peekAll('githubUser').objectAt(0);
      const githubOrgs = store.findAll('githubOrg');
      return _rsvp.default.hash({
        buildpack,
        owners,
        githubUser,
        githubOrgs
      });
    }
  });
  _exports.default = _default;
});