define("buildpack-registry/templates/components/buildpack-publish", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "uUl/PTIO",
    "block": "[[[10,0],[14,0,\"w-100\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"dark-gray lh-copy mb2\"],[12],[1,\"\\n    Publish from commit:\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"mb2 w-100\"],[14,\"data-test-target\",\"publish-options-section\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"data-test-target\",\"selected\",\"onChange\",\"options\"],[\"publish-options\",[30,0,[\"selectedRef\"]],[28,[37,1],[[30,0],\"setSelectedRef\"],null],[33,2]]],[[\"default\"],[[[[1,\"      \"],[1,[30,1,[\"name\"]]],[41,[30,1,[\"shortCommit\"]],[[[1,\" - \"],[1,[30,1,[\"shortCommit\"]]]],[]],null],[1,\"\\n\"]],[1]]]]],[1,\"  \"],[13],[1,\"\\n\\n\"],[41,[33,4,[\"requires2fa\"]],[[[1,\"    \"],[10,0],[14,0,\"mb2\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"hk-label mb2 db\"],[14,\"for\",\"two-factor-token\"],[12],[1,\"\\n        Two Factor Code:\\n      \"],[13],[1,\"\\n      \"],[1,[28,[35,5],null,[[\"type\",\"autocomplete\",\"id\",\"value\",\"placeholder\",\"class\"],[\"password\",\"false\",\"two-factor-token\",[33,6],\"One Time Password or Yubikey\",\"hk-input w-100\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"tr\"],[12],[1,\"\\n    \"],[1,[28,[35,7],null,[[\"default\",\"onClick\",\"data-test-target\"],[\"Publish\",[28,[37,1],[[30,0],\"publishBuildpackRevision\",[33,4],[33,8,[\"name\"]],[33,6]],null],\"publish-button\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"ref\"],false,[\"hk-select\",\"action\",\"groupedRefs\",\"if\",\"buildpack\",\"input\",\"twoFactorToken\",\"hk-button\",\"selectedRef\"]]",
    "moduleName": "buildpack-registry/templates/components/buildpack-publish.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});