define("buildpack-registry/templates/buildpacks/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2hqxBtA3",
    "block": "[[[10,0],[14,0,\"ph4 pv6 flex-auto\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"center mw9\"],[12],[1,\"\\n\"],[41,[33,1,[\"buildpacks\"]],[[[1,\"      \"],[1,[28,[35,2],null,[[\"buildpacks\"],[[33,1,[\"buildpacks\"]]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"mw7 center ma4 shadow-outer-1 bg-white br2 flex flex-column items-center pa4\"],[14,\"data-test-target\",\"buildpacks-empty-state\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"f2 black mv0 pv0\"],[12],[1,\"Register your first buildpack\"],[13],[1,\"\\n        \"],[10,0],[14,0,\"mv2 dark-gray lh-copy tc\"],[12],[1,\"\\n          To get started, click the button below to register a new buildpack.\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"flex flex-column items-center\"],[12],[1,\"\\n\"],[6,[39,3],null,[[\"class\",\"route\"],[\"hk-button--primary\",\"buildpacks.buildpack-new\"]],[[\"default\"],[[[[1,\"            Register Buildpack\\n\"]],[]]]]],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[6,[39,4],null,[[\"to\"],[\"wormhole-destination-create-buildpack\"]],[[\"default\"],[[[[6,[39,3],null,[[\"class\",\"route\"],[\"hk-button--secondary\",\"buildpacks.buildpack-new\"]],[[\"default\"],[[[[1,\"    Register Buildpack\\n\"]],[]]]]]],[]]]]]],[],false,[\"if\",\"model\",\"buildpack-list\",\"link-to\",\"ember-wormhole\"]]",
    "moduleName": "buildpack-registry/templates/buildpacks/index.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});