define("ember-attacher/components/attach-popover", ["exports", "@ember-decorators/component", "@ember-decorators/object", "@ember/object", "@ember/component", "ember-attacher/defaults", "ember-attacher/templates/components/attach-popover", "@ember/runloop", "@ember/application", "@ember/object/internals", "@ember/string", "ember-attacher/-debug/helpers", "@ember/debug", "@ember/utils"], function (_exports, _component, _object, _object2, _component2, _defaults, _attachPopover, _runloop, _application, _internals, _string, _helpers, _debug, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const classic = __EMBER_CLASSIC_DECORATOR;
  let AttachPopover = (_dec = (0, _component.layout)(_attachPopover.default), _dec2 = (0, _component.tagName)(''), _dec3 = (0, _object2.computed)('_transitionDuration'), _dec4 = (0, _object2.computed)('class', 'arrow', 'animation', '_isStartingAnimation'), _dec5 = (0, _object2.computed)('style', '_transitionDuration'), _dec6 = (0, _object2.computed)('_envConfig', 'configKey'), _dec7 = (0, _object2.computed)('hideOn'), _dec8 = (0, _object2.computed)('arrow', 'flip', 'modifiers'), _dec9 = (0, _object2.computed)('showOn'), _dec10 = (0, _object.observes)('hideOn', 'showOn', 'popperTarget'), _dec11 = (0, _object.observes)('isShown'), classic(_class = _dec(_class = _dec2(_class = (_class2 = class AttachPopover extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "configKey", 'popover');
      _defineProperty(this, "animation", _defaults.default.animation);
      _defineProperty(this, "arrow", _defaults.default.arrow);
      _defineProperty(this, "flip", _defaults.default.flip);
      _defineProperty(this, "hideDelay", _defaults.default.hideDelay);
      _defineProperty(this, "hideDuration", _defaults.default.hideDuration);
      _defineProperty(this, "hideOn", _defaults.default.hideOn);
      _defineProperty(this, "interactive", _defaults.default.interactive);
      _defineProperty(this, "isOffset", _defaults.default.isOffset);
      _defineProperty(this, "isShown", _defaults.default.isShown);
      _defineProperty(this, "lazyRender", _defaults.default.lazyRender);
      _defineProperty(this, "onChange", null);
      _defineProperty(this, "placement", _defaults.default.placement);
      _defineProperty(this, "popperContainer", _defaults.default.popperContainer);
      _defineProperty(this, "popperOptions", _defaults.default.popperOptions);
      _defineProperty(this, "popperTarget", null);
      _defineProperty(this, "renderInPlace", _defaults.default.renderInPlace);
      _defineProperty(this, "showDelay", _defaults.default.showDelay);
      _defineProperty(this, "showDuration", _defaults.default.showDuration);
      _defineProperty(this, "showOn", _defaults.default.showOn);
      _defineProperty(this, "style", _defaults.default.style);
      _defineProperty(this, "useCapture", _defaults.default.useCapture);
      _defineProperty(this, "_mustRender", false);
      _defineProperty(this, "_transitionDuration", 0);
    }
    // Exposed via the named yield to enable custom hide events
    hide() {
      this._hide();
    }
    _registerAPI(api) {
      this._disableEventListeners = api.disableEventListeners;
      this._enableEventListeners = api.enableEventListeners;
      this._popperElement = api.popperElement;
      this._update = api.update;
      if (!this.isDestroying && !this.isDestroyed) {
        if (this.registerAPI !== undefined) {
          this.registerAPI(api);
        }
        if (this._isHidden) {
          // Hide the attachment until it has been positioned,
          // preventing jank during initial positioning
          this._popperElement.style.visibility = 'hidden';

          // The attachment has no width if initially hidden. This can cause it to be positioned so
          // far to the right that it overflows the screen until enough updates fix its position.
          // We avoid this by positioning initially hidden elements in the top left of the screen.
          // The attachment will then correctly update its position from the first this._show()
          this._popperElement.style.transform = null;
          this._popperElement.style.display = this.isShown ? '' : 'none';
        }
      }
    }

    // The circle element needs a special duration that is slightly faster than the popper's
    // transition, this prevents text from appearing outside the circle as it fills the background
    get _circleTransitionDuration() {
      return (0, _string.htmlSafe)(`transition-duration: ${Math.round(this._transitionDuration / 1.25)}ms`);
    }
    get _class() {
      const showOrHideClass = `ember-attacher-${this._isStartingAnimation ? 'show' : 'hide'}`;
      const arrowClass = `ember-attacher-${this.arrow ? 'with' : 'without'}-arrow`;
      return `ember-attacher-${this.animation} ${this.class || ''} ${showOrHideClass} ${arrowClass}`;
    }
    get _style() {
      const style = this.style;
      const transitionDuration = this._transitionDuration;
      (true && (0, _debug.warn)('@ember/string/htmlSafe must be used for any `style` passed to ember-attacher', (0, _utils.isEmpty)(style) || (0, _string.isHTMLSafe)(style), {
        id: 'ember-attacher-require-html-safe-style'
      }));
      return (0, _string.htmlSafe)(`transition-duration: ${transitionDuration}ms; ${style}`);
    }

    // This is memoized so it can be used by both attach-popover and attach-tooltip
    get _envConfig() {
      return (0, _application.getOwner)(this).resolveRegistration('config:environment').emberAttacher || {};
    }
    get _config() {
      return {
        ...this._envConfig,
        ...this._envConfig[this.configKey]
      };
    }
    get _hideOn() {
      let hideOn = this.hideOn;
      if (hideOn === undefined) {
        hideOn = _defaults.default.hideOn;
      }
      return hideOn === null ? [] : hideOn.split(' ');
    }
    get _modifiers() {
      // Copy the modifiers since we might write to the provided hash
      const modifiers = this.modifiers ? Object.assign({}, this.modifiers) : {};
      const arrow = this.arrow;
      if (typeof arrow === 'boolean' && !modifiers.arrow) {
        modifiers.arrow = {
          enabled: arrow
        };
      }
      const flipString = this.flip;
      if (flipString) {
        const flipModifier = {
          behavior: flipString.split(' ')
        };
        if (!modifiers.flip) {
          modifiers.flip = flipModifier;
        } else if (!modifiers.flip.behavior) {
          // Copy the flip modifier since we are altering the provided hash
          modifiers.flip = Object.assign({}, modifiers.flip, flipModifier);
        }
      }
      return modifiers;
    }
    _setIsVisibleAfterDelay(isVisible, delay) {
      if (!this._popperElement) {
        this._animationTimeout = requestAnimationFrame(() => {
          this._animationTimeout = this._setIsVisibleAfterDelay(isVisible, delay);
        });
        return;
      }
      const onChange = this.onChange;
      if (delay) {
        this._delayedVisibilityToggle = (0, _runloop.later)(this, () => {
          this._animationTimeout = requestAnimationFrame(() => {
            if (!this.isDestroyed && !this.isDestroying) {
              this._popperElement.style.display = isVisible ? '' : 'none';

              // Prevent jank by making the attachment invisible until positioned.
              // The visibility style will be toggled by this._startShowAnimation()
              this._popperElement.style.visibility = isVisible ? 'hidden' : '';
              if (onChange) {
                onChange(isVisible);
              }
            }
          });
        }, delay);
      } else {
        this._popperElement.style.display = isVisible ? '' : 'none';

        // Prevent jank by making the attachment invisible until positioned.
        // The visibility style will be toggled by this._startShowAnimation()
        this._popperElement.style.visibility = isVisible ? 'hidden' : '';
        if (onChange) {
          onChange(isVisible);
        }
      }
    }

    // This is set to true when the popover is shown in order to override lazyRender=false

    get _showOn() {
      let showOn = this.showOn;
      if (showOn === undefined) {
        showOn = _defaults.default.showOn;
      }
      return showOn === null ? [] : showOn.split(' ');
    }
    /**
     * ================== LIFECYCLE HOOKS ==================
     */

    init() {
      super.init(...arguments);

      // Used to determine the attachments initial parent element
      this._parentFinder = self.document ? self.document.createTextNode('') : '';

      // Holds the current popper target so event listeners can be removed if the target changes
      this._currentTarget = null;

      // The debounced _hide() and _show() are stored here so they can be cancelled when necessary
      this._delayedVisibilityToggle = null;
      this.id = this.id || `${(0, _internals.guidFor)(this)}-popper`;

      // The final source of truth on whether or not all _hide() or _show() actions have completed
      this._isHidden = true;

      // Holds a delayed function to toggle the visibility of the attachment.
      // Used to make sure animations can complete before the attachment is hidden.
      this._animationTimeout = null;

      // Used to store event listeners so they can be removed when necessary.
      this._hideListenersOnDocumentByEvent = {};
      this._hideListenersOnTargetByEvent = {};
      this._showListenersOnTargetByEvent = {};

      // Hacks to make sure event listeners have the right context and are still removable
      this._debouncedHideIfMouseOutsideTargetOrAttachment = this._debouncedHideIfMouseOutsideTargetOrAttachment.bind(this);
      this._hide = this._hide.bind(this);
      this._hideAfterDelay = this._hideAfterDelay.bind(this);
      this._hideIfMouseOutsideTargetOrAttachment = this._hideIfMouseOutsideTargetOrAttachment.bind(this);
      this._hideOnClickOut = this._hideOnClickOut.bind(this);
      this._hideOnEscapeKey = this._hideOnEscapeKey.bind(this);
      this._hideOnLostFocus = this._hideOnLostFocus.bind(this);
      this._hideOnMouseLeaveTarget = this._hideOnMouseLeaveTarget.bind(this);
      this._show = this._show.bind(this);
      this._showAfterDelay = this._showAfterDelay.bind(this);
      this._setUserSuppliedDefaults();
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      (0, _helpers.stripInProduction)(() => {
        // eslint-disable-next-line ember/no-attrs-in-components
        const attrs = this.attrs || {};
        const userDefaults = this._config;
        let arrow;
        if (attrs.arrow !== undefined) {
          arrow = attrs.arrow.value;
        } else if (userDefaults.arrow !== undefined) {
          arrow = userDefaults.arrow;
        } else {
          arrow = _defaults.default.arrow;
        }
        let animation;
        if (attrs.animation !== undefined) {
          animation = attrs.animation.value;
        } else if (userDefaults.animation !== undefined) {
          animation = userDefaults.animation;
        } else {
          animation = _defaults.default.animation;
        }
        if (arrow && animation === 'fill') {
          (true && (0, _debug.warn)('Animation: \'fill\' is not compatible with arrow: true', {
            id: 70015
          }));
        }
        this._lastUseCaptureArgumentValue = this.useCapture;
      });
    }
    didUpdateAttrs() {
      super.didUpdateAttrs(...arguments);
      (0, _helpers.stripInProduction)(() => {
        if (this.useCapture !== this._lastUseCaptureArgumentValue) {
          (true && (0, _debug.warn)('The value of the useCapture argument was mutated', {
            id: 'ember-attacher.use-capture-mutated'
          }));
        }
      });
    }
    _setUserSuppliedDefaults() {
      const userDefaults = this._config;

      // Exit early if no custom defaults are found
      if (!Object.keys(userDefaults).length) {
        return;
      }

      // eslint-disable-next-line ember/no-attrs-in-components
      const attrs = this.attrs || {};
      for (const key in userDefaults) {
        (0, _helpers.stripInProduction)(() => {
          // eslint-disable-next-line no-prototype-builtins
          if (!['popover', 'tooltip', 'class'].includes(key) && !_defaults.default.hasOwnProperty(key)) {
            (true && (0, _debug.warn)(`Unknown property given as an ember-attacher default: ${key}`, {
              id: 700152
            }));
          }
        });

        // Don't override attrs manually passed into the component
        if (attrs[key] === undefined) {
          if (key === 'arrow') {
            this.set('arrow', userDefaults[key]);
          } else {
            this[key] = userDefaults[key];
          }
        }
      }
    }
    didInsertElement() {
      super.didInsertElement(...arguments);
      this._initializeAttacher();
    }
    _initializeAttacher() {
      this._removeEventListeners();
      this.set('_currentTarget', this.popperTarget || this._parentFinder.parentNode);
      this._addListenersForShowEvents();
      if (!this._isHidden || this.isShown) {
        this._addListenersForHideEvents();

        // Even if the attachment is already shown, we still want to
        // call this._show() to make sure its position is updated for a potentially new target.
        this._show();
      }
    }
    _addListenersForShowEvents() {
      if (!this._currentTarget) {
        return;
      }
      this._showOn.forEach(event => {
        this._showListenersOnTargetByEvent[event] = this._showAfterDelay;
        this._currentTarget.addEventListener(event, this._showAfterDelay, this.useCapture);
      });
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      cancelAnimationFrame(this._animationTimeout);
      (0, _runloop.cancel)(this._delayedVisibilityToggle);
      this._removeEventListeners();
    }
    _removeEventListeners() {
      Object.keys(this._hideListenersOnDocumentByEvent).forEach(eventType => {
        document.removeEventListener(eventType, this._hideListenersOnDocumentByEvent[eventType], this.useCapture);
        delete this._hideListenersOnDocumentByEvent[eventType];
      });
      if (!this._currentTarget) {
        return;
      }
      [this._hideListenersOnTargetByEvent, this._showListenersOnTargetByEvent].forEach(eventToListener => {
        Object.keys(eventToListener).forEach(event => {
          this._currentTarget.removeEventListener(event, eventToListener[event], this.useCapture);
        });
      });
    }
    _targetOrTriggersChanged() {
      this._initializeAttacher();
    }
    _isShownChanged() {
      const isShown = this.isShown;
      if (isShown === true && this._isHidden) {
        this._show();

        // Add the hide listeners in the next run loop to avoid conflicts
        // where clicking triggers both an isShown toggle and a clickout.
        (0, _runloop.next)(this, () => this._addListenersForHideEvents());
      } else if (isShown === false && !this._isHidden) {
        this._hide();
      }
    }

    /**
     * ================== SHOW ATTACHMENT LOGIC ==================
     */

    _showAfterDelay() {
      (0, _runloop.cancel)(this._delayedVisibilityToggle);
      this.set('_mustRender', true);
      this._addListenersForHideEvents();
      const showDelay = parseInt(this.showDelay);
      this._delayedVisibilityToggle = (0, _runloop.debounce)(this, this._show, showDelay, !showDelay);
    }
    _show() {
      cancelAnimationFrame(this._animationTimeout);
      if (!this._currentTarget) {
        return;
      }
      this.set('_mustRender', true);

      // Make the attachment visible immediately so transition animations can take place
      this._setIsVisibleAfterDelay(true, 0);
      this._startShowAnimation();
    }
    _startShowAnimation() {
      // Start the show animation on the next cycle so CSS transitions can have an effect.
      // If we start the animation immediately, the transition won't work because
      // `display: none` => `display: ''` is not transition-able.
      // All included animations set opaque: 0, so the attachment is still effectively hidden until
      // the final RAF occurs.
      this._animationTimeout = requestAnimationFrame(() => {
        if (this.isDestroyed || this.isDestroying || !this._currentTarget) {
          return;
        }
        const popperElement = this._popperElement;

        // Wait until the element is visible before continuing
        if (!popperElement || popperElement.style.display === 'none') {
          this._animationTimeout = this._startShowAnimation();
          return;
        }
        this._enableEventListeners();
        this._update();

        // Wait for the above positioning to take effect before starting the show animation,
        // else the positioning itself will be animated, causing animation glitches.
        this._animationTimeout = requestAnimationFrame(() => {
          if (this.isDestroyed || this.isDestroying || !this._currentTarget) {
            return;
          }
          (0, _runloop.run)(() => {
            if (this.isDestroyed || this.isDestroying || !this._currentTarget) {
              return;
            }
            // Make the popper element visible now that it has been positioned
            popperElement.style.visibility = '';
            this.set('_transitionDuration', parseInt(this.showDuration));
            this.set('_isStartingAnimation', true);
            popperElement.setAttribute('aria-hidden', 'false');
          });
          this._isHidden = false;
        });
      });
    }

    /**
     * ================== HIDE ATTACHMENT LOGIC ==================
     */

    _hideAfterDelay() {
      (0, _runloop.cancel)(this._delayedVisibilityToggle);
      const hideDelay = parseInt(this.hideDelay);
      this._delayedVisibilityToggle = (0, _runloop.debounce)(this, this._hide, hideDelay, !hideDelay);
    }
    _hide() {
      if (!this._popperElement) {
        this._animationTimeout = requestAnimationFrame(() => {
          this._animationTimeout = this._hide();
        });
        return;
      }
      cancelAnimationFrame(this._animationTimeout);
      this._removeListenersForHideEvents();
      this._animationTimeout = requestAnimationFrame(() => {
        // Avoid a race condition where we attempt to hide after the component is being destroyed.
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        const hideDuration = parseInt(this.hideDuration);
        (0, _runloop.run)(() => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }
          this.set('_transitionDuration', hideDuration);
          this.set('_isStartingAnimation', false);
          this._popperElement.setAttribute('aria-hidden', 'true');

          // Wait for any animations to complete before hiding the attachment
          this._setIsVisibleAfterDelay(false, hideDuration);
        });
        this._disableEventListeners();
        this._isHidden = true;
      });
    }

    /**
     * ================== HIDE LISTENERS ==================
     */

    _addListenersForHideEvents() {
      const hideOn = this._hideOn;
      const target = this._currentTarget;

      // Target or component was destroyed
      if (!target || this.isDestroyed || this.isDestroying) {
        return;
      }
      if (hideOn.includes('click')) {
        const showOnClickListener = this._showListenersOnTargetByEvent.click;
        if (showOnClickListener) {
          target.removeEventListener('click', showOnClickListener, this.useCapture);
          delete this._showListenersOnTargetByEvent.click;
        }
        this._hideListenersOnTargetByEvent.click = this._hideAfterDelay;
        target.addEventListener('click', this._hideAfterDelay, this.useCapture);
      }
      if (hideOn.includes('clickout')) {
        const clickoutEvent = 'ontouchstart' in window ? 'touchend' : 'click';
        this._hideListenersOnDocumentByEvent[clickoutEvent] = this._hideOnClickOut;
        document.addEventListener(clickoutEvent, this._hideOnClickOut, this.useCapture);
      }
      if (hideOn.includes('escapekey')) {
        this._hideListenersOnDocumentByEvent.keydown = this._hideOnEscapeKey;
        document.addEventListener('keydown', this._hideOnEscapeKey, this.useCapture);
      }

      // Hides the attachment when the mouse leaves the target
      // (or leaves both target and attachment for interactive attachments)
      if (hideOn.includes('mouseleave')) {
        this._hideListenersOnTargetByEvent.mouseleave = this._hideOnMouseLeaveTarget;
        target.addEventListener('mouseleave', this._hideOnMouseLeaveTarget, this.useCapture);
      }

      // Hides the attachment when focus is lost on the target
      ['blur', 'focusout'].forEach(eventType => {
        if (hideOn.includes(eventType)) {
          this._hideListenersOnTargetByEvent[eventType] = this._hideOnLostFocus;
          target.addEventListener(eventType, this._hideOnLostFocus, this.useCapture);
        }
      });
    }
    _hideOnMouseLeaveTarget() {
      if (this.interactive) {
        // TODO(kjb) Should debounce this, but hiding appears sluggish if you debounce.
        //   - If you debounce with immediate fire, you get a bug where you can move out of the
        //   attachment and not trigger the hide because the hide check was debounced
        //   - Ideally we would debounce with an immediate run, then instead of debouncing, we would
        //   queue another fire at the end of the debounce period
        if (!this._hideListenersOnDocumentByEvent.mousemove) {
          this._hideListenersOnDocumentByEvent.mousemove = this._hideIfMouseOutsideTargetOrAttachment;
          document.addEventListener('mousemove', this._hideIfMouseOutsideTargetOrAttachment, this.useCapture);
        }
      } else {
        this._hideAfterDelay();
      }
    }
    _debouncedHideIfMouseOutsideTargetOrAttachment(event) {
      (0, _runloop.debounce)(this, this._hideIfMouseOutsideTargetOrAttachment, event, 10);
    }
    _hideIfMouseOutsideTargetOrAttachment(event) {
      const target = this._currentTarget;
      if (!target) {
        return;
      }

      // If cursor is not on the attachment or target, hide the popover
      if (!target.contains(event.target) && !(this.isOffset && this._isCursorBetweenTargetAndAttachment(event)) && this._popperElement && !this._popperElement.contains(event.target)) {
        // Remove this listener before hiding the attachment
        delete this._hideListenersOnDocumentByEvent.mousemove;
        document.removeEventListener('mousemove', this._hideIfMouseOutsideTargetOrAttachment, this.useCapture);
        this._hideAfterDelay();
      }
    }
    _isCursorBetweenTargetAndAttachment(event) {
      if (!this._currentTarget) {
        return;
      }
      const {
        clientX,
        clientY
      } = event;
      const attachmentPosition = this._popperElement.getBoundingClientRect();
      const targetPosition = this._currentTarget.getBoundingClientRect();
      const isBetweenLeftAndRight = clientX > Math.min(attachmentPosition.left, targetPosition.left) && clientX < Math.max(attachmentPosition.right, targetPosition.right);
      const isBetweenTopAndBottom = clientY > Math.min(attachmentPosition.top, targetPosition.top) && clientY < Math.max(attachmentPosition.bottom, targetPosition.bottom);

      // Check if cursor is between a left-flipped attachment
      if (attachmentPosition.right < targetPosition.left && clientX >= attachmentPosition.right && clientX <= targetPosition.left && isBetweenTopAndBottom) {
        return true;
      }

      // Check if cursor is between a right-flipped attachment
      if (attachmentPosition.left > targetPosition.right && clientX <= attachmentPosition.left && clientX >= targetPosition.right && isBetweenTopAndBottom) {
        return true;
      }

      // Check if cursor is between a bottom-flipped attachment
      if (attachmentPosition.top > targetPosition.bottom && clientY <= attachmentPosition.top && clientY >= targetPosition.bottom && isBetweenLeftAndRight) {
        return true;
      }

      // Check if cursor is between a top-flipped attachment
      if (attachmentPosition.bottom < targetPosition.top && clientY >= attachmentPosition.bottom && clientY <= targetPosition.top && isBetweenLeftAndRight) {
        return true;
      }
      return false;
    }
    _hideOnClickOut(event) {
      const targetReceivedClick = this._currentTarget.contains(event.target);
      if (this.interactive) {
        if (!targetReceivedClick && !this._popperElement.contains(event.target)) {
          this._hideAfterDelay();
        }
      } else if (!targetReceivedClick) {
        this._hideAfterDelay();
      }
    }
    _hideOnEscapeKey(event) {
      if (event.keyCode === 27) {
        return this._hideAfterDelay();
      }
    }
    _hideOnLostFocus(event) {
      if (event.relatedTarget === null) {
        this._hideAfterDelay();
      }
      if (!this._currentTarget) {
        return;
      }
      const targetContainsFocus = this._currentTarget.contains(event.relatedTarget);
      if (this.interactive) {
        if (!targetContainsFocus && !this._popperElement.contains(event.relatedTarget)) {
          this._hideAfterDelay();
        }
      } else if (!targetContainsFocus) {
        this._hideAfterDelay();
      }
    }
    _removeListenersForHideEvents() {
      Object.keys(this._hideListenersOnDocumentByEvent).forEach(eventType => {
        document.removeEventListener(eventType, this._hideListenersOnDocumentByEvent[eventType], this.useCapture);
        delete this._hideListenersOnDocumentByEvent[eventType];
      });
      const showOn = this._showOn;
      const target = this._currentTarget;

      // The target was destroyed, nothing to remove listeners from
      if (!target) {
        return;
      }

      // Switch clicking back to a show event
      if (showOn.includes('click')) {
        const hideOnClickListener = this._hideListenersOnTargetByEvent.click;
        if (hideOnClickListener) {
          target.removeEventListener('click', hideOnClickListener, this.useCapture);
          delete this._hideListenersOnTargetByEvent.click;
        }
        this._showListenersOnTargetByEvent.click = this._showAfterDelay;
        target.addEventListener('click', this._showAfterDelay, this.useCapture);
      }
      ['blur', 'focusout', 'mouseleave'].forEach(eventType => {
        const listener = this._hideListenersOnTargetByEvent[eventType];
        if (listener) {
          target.removeEventListener(eventType, listener, this.useCapture);
          delete this._hideListenersOnTargetByEvent[eventType];
        }
      });
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "hide", [_object2.action], Object.getOwnPropertyDescriptor(_class2.prototype, "hide"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_registerAPI", [_object2.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_registerAPI"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_circleTransitionDuration", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "_circleTransitionDuration"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_class", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "_class"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_style", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "_style"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_config", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "_config"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_hideOn", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "_hideOn"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_modifiers", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "_modifiers"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_showOn", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "_showOn"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_targetOrTriggersChanged", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "_targetOrTriggersChanged"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_isShownChanged", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "_isShownChanged"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  _exports.default = AttachPopover;
});