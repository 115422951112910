define("ember-data-factory-guy/mocks/mock-query-request", ["exports", "ember-data-factory-guy/mocks/mock-get-request"], function (_exports, _mockGetRequest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MockQueryRequest extends _mockGetRequest.default {
    /**
     * By default this query will return a payload of [] or empty array
     *
     * @param modelName
     * @param queryParams
     */
    constructor(modelName) {
      let queryParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      super(modelName, 'query', {
        defaultResponse: [],
        queryParams
      });
      this.setValidReturnsKeys(['models', 'json', 'ids', 'headers']);
    }
  }
  _exports.default = MockQueryRequest;
});