define("ember-data-factory-guy/builder/active-model-fixture-builder", ["exports", "ember-data-factory-guy/builder/fixture-builder", "ember-data-factory-guy/converter/active-model-fixture-converter", "ember-data-factory-guy/payload/rest-payload"], function (_exports, _fixtureBuilder, _activeModelFixtureConverter, _restPayload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   Fixture Builder for ActiveModelSerializer
   */
  class ActiveModelFixtureBuilder extends _fixtureBuilder.default {
    constructor(store) {
      super(store, _activeModelFixtureConverter.default, _restPayload.default);
    }

    /**
     ActiveModelAdapter converts them automatically for status 422
      @param errors
     @returns {*}
     */
    convertResponseErrors(errors, status) {
      if (status === 422) {
        return errors;
      } else {
        return super.convertResponseErrors(errors, status);
      }
    }

    /**
     Map single object to response json.
      Allows custom serializing mappings and meta data to be added to requests.
      @param {String} modelName model name
     @param {Object} json Json object from record.toJSON
     @return {Object} responseJson
     */
    normalize(modelName, payload) {
      return {
        [modelName]: payload
      };
    }
  }
  _exports.default = ActiveModelFixtureBuilder;
});