define("buildpack-registry/components/input-with-validation-status", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    tagName: '',
    isFieldChanged: false,
    inputValue: (0, _computed.oneWay)('value'),
    isFieldNotEmpty: (0, _computed.notEmpty)('value'),
    isInvalid: (0, _computed.not)('isValid'),
    isShowingStatus: (0, _computed.and)('isFieldNotEmpty', 'isFieldChanged'),
    inputClasses: (0, _object.computed)('isValid', 'isValidating', 'isShowingStatus', function () {
      let classes = this.get('class');
      if (this.get('isShowingStatus')) {
        if (this.get('isValidating')) {
          classes += ' br--left z-1';
        } else if (this.get('isValid')) {
          classes += ' br--left z-1 hk-focus-ring--green';
        } else {
          classes += ' br--left z-1 hk-focus-ring--red';
        }
      }
      return classes;
    }),
    actions: {
      onChange(newValue) {
        // Some keys (arrow, shift, etc) don't change the value, and we only want
        // this to fire when the field has changed.
        if (newValue !== this.get('value')) {
          this.set('value', newValue);
          this.set('isFieldChanged', true);
        }
      }
    }
  });
  _exports.default = _default;
});