define("buildpack-registry/templates/components/input-with-validation-status", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "zkiVOZ1y",
    "block": "[[[10,0],[14,0,\"flex\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"key-up\",\"id\",\"value\",\"name\",\"class\",\"autofocus\",\"disabled\",\"maxlength\",\"placeholder\",\"readonly\"],[[28,[37,1],[[30,0],\"onChange\"],null],[33,2],[33,3],[33,4],[33,5],[33,6],[33,7],[33,8],[33,9],[28,[33,10],null,null]]]]],[1,\"\\n\\n\"],[41,[33,12],[[[41,[33,13],[[[1,\"      \"],[10,0],[14,0,\"flex items-center bg-light-silver br1 br--right bt br bb b--light-gray ph2\"],[12],[1,\"\\n        \"],[8,[39,14],null,[[\"@name\",\"@iconClass\",\"@size\"],[\"loading-16\",\"malibu-fill-gradient-dark-gray\",16]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[33,15],[[[1,\"      \"],[10,0],[14,0,\"flex items-center bg-lightest-green br1 br--right bt br bb b--light-green ph2\"],[12],[1,\"\\n        \"],[8,[39,14],null,[[\"@name\",\"@iconClass\",\"@size\"],[\"success-badge-16\",\"malibu-fill-gradient-green\",16]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"flex items-center bg-lightest-red br1 br--right bt br bb b--light-red ph2\"],[12],[1,\"\\n        \"],[8,[39,14],null,[[\"@name\",\"@iconClass\",\"@size\"],[\"failure-badge-16\",\"malibu-fill-gradient-red\",16]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]],[]]]],[]],null],[13],[1,\"\\n\"]],[],false,[\"input\",\"action\",\"id\",\"inputValue\",\"name\",\"inputClasses\",\"autofocus\",\"disabled\",\"maxlength\",\"placeholder\",\"readonly\",\"if\",\"isShowingStatus\",\"isValidating\",\"malibu-icon\",\"isValid\"]]",
    "moduleName": "buildpack-registry/templates/components/input-with-validation-status.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});