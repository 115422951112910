define("ember-data-factory-guy/mocks/mock-update-request", ["exports", "@ember/debug", "@ember/polyfills", "ember-data-factory-guy/factory-guy", "ember-data-factory-guy/mocks/mock-store-request", "ember-data-factory-guy/mocks/attribute-matcher", "ember-data-factory-guy/mocks/maybe-id-url-match"], function (_exports, _debug, _polyfills, _factoryGuy, _mockStoreRequest, _attributeMatcher, _maybeIdUrlMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MockUpdateRequest extends (0, _maybeIdUrlMatch.default)((0, _attributeMatcher.default)(_mockStoreRequest.default)) {
    constructor(modelName) {
      let {
        id,
        model
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      super(modelName, 'updateRecord');
      this.id = id;
      this.model = model;
      this.returnArgs = {};
      this.matchArgs = {};
      this.setupHandler();
    }
    getType() {
      return _factoryGuy.default.updateHTTPMethod(this.modelName);
    }

    /**
     * Create fake snaphot with adapterOptions and record.
     *
     * Override the parent to find the model in the store if there is
     * an id available
     *
     * @returns {{adapterOptions: (*|Object), record: (*|DS.Model)}}
     */
    makeFakeSnapshot() {
      let snapshot = super.makeFakeSnapshot();
      if (this.id && !this.model) {
        snapshot.record = _factoryGuy.default.store.peekRecord(this.modelName, this.id);
      }
      return snapshot;
    }

    /**
     This returns only accepts attrs key
      These attrs are those attributes or relationships that
     you would like returned with the model when the update succeeds.
      You can't user returns if you use mockUpdate with only a modelName like:
     mockUpdate('user'); ( no id specified )
      @param {Object} returns attributes and or relationships to send with payload
     */
    returns(returns) {
      this.validateReturnsOptions(returns);
      (true && !(this.id) && (0, _debug.assert)(`[ember-data-factory-guy] Can't use returns in
      mockUpdate when update only has modelName and no id`, this.id));
      this.returnArgs = returns.attrs;
      this.add = returns.add;
      return this;
    }

    /**
     Adapters freak out if update payload is non empty and there is no id.
      So, if you use mockUpdate like this:
     mockUpdate('user'); ( no id specified ) this mock will return null
      @returns {*}
     */
    getResponse() {
      this.responseJson = null;
      if (Object.keys(this.returnArgs).length) {
        let args = (0, _polyfills.assign)({}, this.matchArgs, this.returnArgs),
          json = (0, _polyfills.assign)({}, args, {
            id: this.id
          });
        this.responseJson = this.fixtureBuilder.convertForBuild(this.modelName, json);
      }
      return super.getResponse();
    }
  }
  _exports.default = MockUpdateRequest;
});