define("ember-data-factory-guy/mocks/mock-store-request", ["exports", "ember-data-factory-guy/factory-guy", "ember-data-factory-guy/mocks/mock-request"], function (_exports, _factoryGuy, _mockRequest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _mockRequest.default {
    constructor(modelName, requestType) {
      super();
      this.modelName = modelName;
      this.requestType = requestType;
      this.fixtureBuilder = _factoryGuy.default.fixtureBuilder(this.modelName);
    }

    /**
     Used by getUrl to => this.get('id')
      MockGetRequest overrides this since those mocks have a payload with the id
      For mockDelete: If the id is null the url will not include the id, and
     can therefore be used to match any delete for this modelName
     */
    get() {
      if ((arguments.length <= 0 ? undefined : arguments[0]) === 'id') {
        return this.id;
      }
    }

    /**
     * Using adapterOptions for snapshot in GET requests
     *
     * @returns {String}
     */
    getUrl() {
      return _factoryGuy.default.buildURL(this.modelName, this.get('id'), this.makeFakeSnapshot(), this.requestType, this.queryParams);
    }

    /**
     * Create fake snaphot with adapterOptions and record
     *
     * @returns {{adapterOptions: (*|Object), record: (*|DS.Model)}}
     */
    makeFakeSnapshot() {
      return {
        adapterOptions: this.adapterOptions,
        record: this.model
      };
    }
  }
  _exports.default = _default;
});