define("ember-data-factory-guy/missing-sequence-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(message) {
    this.toString = function () {
      return message;
    };
  }
});