define("buildpack-registry/templates/buildpacks/buildpack-publish-success", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "zmbULLPv",
    "block": "[[[10,0],[14,0,\"ph4 pv6 flex-auto\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"w-100 center mw7\"],[12],[1,\"\\n\\n    \"],[10,\"section\"],[14,0,\"ph5 bb b--light-silver mb5 pb5 w-100\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"f2 mb5 dark-gray center\"],[12],[1,\"\\n        Congratulations! Your buildpack has been published.\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"center w-100 w-50-ns\"],[12],[1,\"\\n        \"],[1,[28,[35,0],null,[[\"buildpack\",\"revision\"],[[33,1,[\"buildpack\"]],[33,1,[\"revision\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"tr mv3\"],[12],[1,\"\\n\"],[6,[39,2],null,[[\"class\",\"tagName\",\"route\"],[\"hk-button--secondary\",\"button\",\"buildpacks\"]],[[\"default\"],[[[[1,\"        Back to Buildpacks\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"buildpack-card\",\"model\",\"link-to\",\"component\",\"-outlet\"]]",
    "moduleName": "buildpack-registry/templates/buildpacks/buildpack-publish-success.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});