define("buildpack-registry/routes/buildpacks/buildpack-publish-success", ["exports", "@ember/routing/route", "rsvp"], function (_exports, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    model(params) {
      // the API doesn't support fetching by ID yet... so we have to load all of them if it isn't
      // present in the in-memory store. Ideally, users will only visit this page after publishing,
      // so it will be in the store, but if not, we can fallback to something else.
      const revision = this.store.findRecord('buildpack-revision', params.revision_id).catch(() => {
        return this.store.findRecord('buildpack', params.buildpack_id).then(buildpack => buildpack.get('revisions')).then(revisions => revisions.findBy('id', params.revision_id));
      });
      const buildpack = this.store.findRecord('buildpack', params.buildpack_id);
      return (0, _rsvp.hash)({
        revision,
        buildpack
      });
    },
    afterModel(_ref) {
      let {
        revision,
        buildpack
      } = _ref;
      if (!revision) {
        this.transitionTo('buildpacks.buildpack-edit', buildpack.get('id'));
      }
    }
  });
  _exports.default = _default;
});