define("ember-data-factory-guy/mocks/mock-find-record-request", ["exports", "ember-data-factory-guy", "ember-data-factory-guy/mocks/mock-get-request"], function (_exports, _emberDataFactoryGuy, _mockGetRequest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MockFindRecordRequest extends _mockGetRequest.default {
    constructor(modelName) {
      super(modelName, 'findRecord');
      this.setValidReturnsKeys(['model', 'json', 'id', 'headers']);
    }

    /**
     * When using returns({id: id}), this is flagged as an idSearch, so
     * that at the last moment when this handler is returning the request response,
     * we can check the store and see if a model with that id exists.
     *
     * If not, then this will be a 404 not found error
     *
     * @param settings
     * @returns {*}
     */
    extraRequestMatches(settings) {
      if (this.idSearch) {
        let model = _emberDataFactoryGuy.default.store.peekRecord(this.modelName, this.get('id'));
        if (!model) {
          // the match still succeeds but the response is failure
          this.fails({
            status: 404
          });
        }
      }
      return super.extraRequestMatches(settings);
    }
  }
  _exports.default = MockFindRecordRequest;
});