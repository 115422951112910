define("buildpack-registry/validators/name-available", ["exports", "rsvp", "@ember/utils"], function (_exports, _rsvp, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateNameAvailable;
  function validateNameAvailable(_ref) {
    let {
      store
    } = _ref;
    return (key, newValue, oldValue, changes, content) => {
      return new _rsvp.Promise(resolve => {
        // Let validatePresence catch this.
        if ((0, _utils.isEmpty)(newValue)) {
          return resolve(true);
        }

        // No change to the original name.
        if (newValue === oldValue) {
          return resolve(true);
        }

        // Validate that the name is available.
        const namespace = changes.namespace || content.get('namespace');
        return store.find('buildpack', `${namespace}/${newValue}`).then(() => {
          return resolve('Name is not available');
        }).catch(err => {
          return resolve(validIf404(err));
        });
      });
    };
  }
  function validIf404(err) {
    const statusCode = getStatusCode(err);
    if (statusCode === 404) {
      return true;
    } else {
      throw err;
    }
  }
  function getStatusCode(err) {
    let code = err.status;
    if (err.isAdapterError) {
      // this is an Ember Data AdapterError, need to dig for the status code.
      const error = err.errors[0] || {};
      code = parseInt(error.status); // Yep, it's a string.
      if (isNaN(code)) {
        code = null;
      }
    }
    return code;
  }
});