define("buildpack-registry/templates/components/file-input", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "WXS6FADE",
    "block": "[[[10,\"label\"],[14,0,\"hk-input w-100 br--left truncate\"],[15,\"for\",[36,0]],[12],[1,[34,1]],[13],[1,\"\\n\"],[10,\"label\"],[14,0,\"hk-button--primary flex items-center br--right\"],[15,\"for\",[36,0]],[12],[1,\"\\n  \"],[10,0],[14,0,\"v-mid\"],[12],[1,\"Choose File\"],[13],[1,\"\\n  \"],[10,\"input\"],[15,1,[36,0]],[15,\"onchange\",[28,[37,2],[[30,0],\"selectFile\"],null]],[15,\"accept\",[36,3]],[14,0,\"dn\"],[14,\"data-test-target\",\"file-input-field\"],[14,4,\"file\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"inputId\",\"labelText\",\"action\",\"accept\"]]",
    "moduleName": "buildpack-registry/templates/components/file-input.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});