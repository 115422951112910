define("buildpack-registry/validators/github-repo", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateGithubRepo;
  function validateGithubRepo() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return (key, newValue, oldValue, changes, content) => {
      const sourceOwner = changes.sourceOwner || content.get('sourceOwner');
      const sourceRepo = changes.sourceRepo || content.get('sourceRepo');
      if ((0, _utils.isBlank)(sourceOwner) || (0, _utils.isBlank)(sourceRepo)) {
        return options.message;
      } else {
        return true;
      }
    };
  }
});