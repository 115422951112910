define("buildpack-registry/templates/components/buildpack-list-type", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "CbiEMiCG",
    "block": "[[[41,[33,1],[[[42,[28,[37,3],[[28,[37,3],[[33,4]],null]],null],null,[[[1,\"    \"],[10,0],[14,\"data-test-target\",\"buildpack-list-group\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"f4 near-black lh-title pb5\"],[14,\"data-test-target\",\"buildpack-list-group-title\"],[12],[1,[30,1,[\"value\"]]],[13],[1,\"\\n      \"],[10,0],[14,0,\"pb6 flex-auto\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"center flex flex-wrap w-100\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,1,[\"items\"]]],null]],null],null,[[[1,\"            \"],[10,0],[14,0,\"w-100 w-50-m w-25-l\"],[12],[1,\"\\n              \"],[1,[28,[35,5],null,[[\"buildpack\"],[[30,2]]]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null]],[]],null]],[\"group\",\"buildpack\"],false,[\"if\",\"hasBuildpacks\",\"each\",\"-track-array\",\"buildpacksByOwner\",\"buildpack-card\"]]",
    "moduleName": "buildpack-registry/templates/components/buildpack-list-type.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});