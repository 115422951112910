define("@heroku/torii-provider-heroku/mixins/heroku-authable", ["exports", "@ember/application", "@ember/object/mixin", "jquery", "@ember/utils"], function (_exports, _application, _mixin, _jquery, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.loginToHeroku = loginToHeroku;
  _exports.rememberAttemptedTransition = rememberAttemptedTransition;
  _exports.sendUserToIdentity = sendUserToIdentity;
  function loginToHeroku(session) {
    return session.open('heroku-oauth2').then(( /*authorization*/
    ) => {
      session.set('isAuthenticated', true);
    });
  }
  function rememberAttemptedTransition(transition) {
    // don't remember without localstorage
    if (!window.localStorage) {
      return;
    }
    let nameOrUrl = transition.intent.name || transition.intent.url;
    if (!(0, _utils.isBlank)(nameOrUrl)) {
      let localStorage = window.localStorage;
      localStorage.setItem('attemptedTransitionUrl', nameOrUrl);
    }
  }
  function sendUserToIdentity(apiKey, baseUrl, scope) {
    const state = Math.random().toString(36).substring(2);
    localStorage.setItem('state', state);
    let params = {
      client_id: apiKey,
      response_type: 'code',
      scope: scope || 'identity',
      state
    };
    window.location = `${baseUrl}?${_jquery.default.param(params)}`;
  }
  var _default = _mixin.default.create({
    actions: {
      loginToHeroku() {
        let session = this.get('session');
        loginToHeroku(session);
      },
      accessDenied() {
        const ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
        const {
          apiKey,
          baseUrl,
          scope
        } = ENV.torii.providers['heroku-oauth2'];
        rememberAttemptedTransition(this.get('session.attemptedTransition'));
        sendUserToIdentity(apiKey, baseUrl, scope);
        /* attempted transition is automatically retried on return from Identity */
      }
    }
  });
  _exports.default = _default;
});