define("ember-data-factory-guy/builder/rest-fixture-builder", ["exports", "ember-data-factory-guy/builder/fixture-builder", "ember-data-factory-guy/converter/rest-fixture-converter", "ember-data-factory-guy/payload/rest-payload"], function (_exports, _fixtureBuilder, _restFixtureConverter, _restPayload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   Fixture Builder for REST based Serializer, like ActiveModelSerializer or
   RESTSerializer
  
   */
  class RESTFixtureBuilder extends _fixtureBuilder.default {
    constructor(store) {
      super(store, _restFixtureConverter.default, _restPayload.default);
    }
    /**
     Map single object to response json.
      Allows custom serializing mappings and meta data to be added to requests.
      @param {String} modelName model name
     @param {Object} json Json object from record.toJSON
     @return {Object} responseJson
     */
    normalize(modelName, payload) {
      return {
        [modelName]: payload
      };
    }
  }
  _exports.default = RESTFixtureBuilder;
});