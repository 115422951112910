define("buildpack-registry/components/file-input", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/runloop"], function (_exports, _component, _object, _computed, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    classNames: ['flex', 'w-100'],
    selectedFileName: (0, _computed.alias)('selectedFile.name'),
    inputId: (0, _object.computed)(function () {
      return `${this.get('elementId')}-input`;
    }),
    labelText: (0, _object.computed)('selectedFileName', function () {
      return this.get('selectedFileName') || 'No file chosen';
    }),
    actions: {
      selectFile() {
        // eslint-disable-next-line no-restricted-globals
        const file = event.target.files.item(0);
        this.set('selectedFile', file);
        if (file) {
          const reader = new FileReader();
          const onSelect = this.get('onSelect');
          reader.onload = () => {
            (0, _runloop.run)(() => {
              onSelect(reader.result);
            });
          };
          reader.readAsDataURL(file);
        } else {
          // When user hits cancel on the file upload dialog.
          const onCancel = this.get('onCancel');
          if (onCancel) {
            onCancel();
          }
        }
      }
    }
  });
  _exports.default = _default;
});