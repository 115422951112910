define('ember-backboard/services/analytics', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    accountId: null,

    backboardUrl: Ember.computed(function () {
      const config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config && config.backboardUrl;
    }),

    source: Ember.computed(function () {
      const config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config && config.backboardSourceName;
    }),

    /**
     * trackUser - Set the user account id to associate with tracking events
     *             This should be called once as soon as a user is authenticated
     *             and all subsequent events will have that user associated with them
     *
     * @param  {type} account The account to associate with the events
     */
    trackUser(account) {
      this.set('accountId', account.get('id'));
    },

    /**
     * logEvent - send an event to backboard
     *
     * @param  {type} noun          Noun describing event name
     * @param  {type} action        Verb describing the event
     * @param  {type} properties={} Object to send along with event
     */
    logEvent(noun, action, properties = {}) {
      const userId = this.get('accountId');
      if (Ember.isEmpty(userId)) {
        // this scenario, at a minimum, happens during the OAuth redirect login
        // as the user's session has not yet been established upon transitioning
        // into the first route
        return;
      }

      const source = this.get('source');

      const event = `${noun} ${action}`;

      // attempt to retrieve and pass along the current path
      const application = Ember.getOwner(this).lookup('controller:application');
      properties.route = application ? application.currentPath : window.location.pathname;

      const url = this._sanitize(window.location.href); // this includes proto and domain
      const path = window.location.pathname;
      const search = this._sanitize(window.location.search); // these are the query params
      const title = document.title; // page title
      const referrer = document.referrer;
      const page = { url, path, search, title, referrer };

      this._transmit({ source, event, userId, properties, page });
    },

    _transmit(payload) {
      const backboardUrl = this.get('backboardUrl');

      // abort this request when backboard is unset
      if (!backboardUrl) {
        return;
      }

      Ember.$.ajax(`${backboardUrl}/hamurai`, {
        global: false, // suppress red bubbles when this endpoint errors
        data: {
          data: b64EncodeUnicode(JSON.stringify(payload))
        }
      });
    },

    _sanitize(str) {
      return str.replace(/code=.{36}/, 'code=[REDACTED]');
    }
  });


  // https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
  // tl;dr of MDN article:
  // JavaScript strings are UTF-16 by default.
  // UTF8 and UTF16 do not share all the same escape characters.
  // an example in dashboard specifically is that the document title has the `·`
  // (middle dot symbol https://unicode-table.com/en/00B7/).
  // Elixir strings are UTF-8, so we need to first escape the string as UTF8 before base64 encoding it
  // so the analytics service can do something with it.
  function b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      return String.fromCharCode('0x' + p1);
    }));
  }
});