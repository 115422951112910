define("buildpack-registry/routes/buildpacks/index", ["exports", "@ember/routing/route", "rsvp"], function (_exports, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    model() {
      return _rsvp.default.hash({
        buildpacks: this.get('store').query('buildpack', {
          userId: '~'
        })
      });
    }
  });
  _exports.default = _default;
});